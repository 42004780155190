import {
  ModalCloseButton,
  ModalHeader,
  StyledModalContent,
} from 'components/system/Modal';
import { useModal } from 'providers/ModalProvider/ModalProvider';
import ReuploadExamModalForm from './ReuploadExamModalForm';
import { IUseReuploadExamModal } from './reuploadExamModalForm.types';

export const useReuploadExamModal = ({
  onClick,
  onCompleted,
}: IUseReuploadExamModal = {}) => {
  const { handleOpenModal, handleCloseModal } = useModal();

  const openReuploadModal = (examId: string) => {
    onClick?.();
    handleOpenModal(
      <StyledModalContent>
        <ModalCloseButton handleClose={handleCloseModal} />
        <ModalHeader title="Re-upload exam" />
        <ReuploadExamModalForm
          examId={examId}
          onClose={handleCloseModal}
          onCompleted={onCompleted}
        />
      </StyledModalContent>,
    );
  };

  return { openReuploadModal };
};
