import { TCurrentExam } from 'components/pages/CreateExam/ExamPanel/Context/ExamPanelContext';
import { Blocker } from 'react-router-dom';
import { Exam } from 'types/mongoose-types';
import { logError } from 'utils/logError';
import { examPriorityOptions } from './examForms.constants';
import {
  ILeaveConfirmationOptions,
  ILogoutConfirmationOptions,
  IPhysicianOptions,
} from './examForms.types';

/**
 * Sets the default values for an exam form.
 * @param exam - The exam object.
 * @param physicianOptions - The options for physicians.
 * @returns The default values for the exam form.
 */
export const setDefaultValues = (
  physicianOptions: IPhysicianOptions[],
  exam?: Partial<Exam | TCurrentExam>,
) => {
  if (!exam) {
    return {};
  }

  const physicianId =
    typeof exam.physician === 'string'
      ? exam.physician
      : exam.physician?._id.toString();

  const defaultValues = {
    examDate: exam?.examDate,
    physician:
      physicianOptions.find((option) => option.value === physicianId) ?? '',
    priority: examPriorityOptions.find(
      (option) => option.value === exam.priority,
    ),
    clinicalIndication: exam.clinicalIndication ?? '',
  };

  return defaultValues;
};

const DEFAULT_VALUES = {
  message: 'You have unsaved changes. What would you like to do?',
  cancelLabel: 'Back to Exam',
  discardLabel: 'Discard Exam',
  saveLabel: 'Save as Draft',
};

export const getSaveableConfirmationOptions = ({
  openFeedbackModal,
  uploadControllerRef,
  resetFormManager,
  onSave,
  onDiscard,
}: ILeaveConfirmationOptions) => {
  const handleComplete = (blocker: Blocker) => {
    blocker.proceed?.();
    resetFormManager();
  };

  const handleError = (
    error: Error,
    blocker: Blocker,
    type: 'save' | 'discard',
  ) => {
    openFeedbackModal({
      type: 'error',
      title: `Failed to ${type} changes`,
      message: 'Please try again later.',
      cancelBtn: { onClick: blocker.reset },
    });
    logError({ error });
  };

  return {
    message: DEFAULT_VALUES.message,
    cancelLabel: DEFAULT_VALUES.cancelLabel,
    discardLabel: DEFAULT_VALUES.discardLabel,
    onDiscard: async (blocker: Blocker) => {
      uploadControllerRef?.current?.abort();
      await onDiscard({
        onCompleted: () => handleComplete(blocker),
        onError: (error) => handleError(error, blocker, 'discard'),
      });
    },
    additionalOptions: [
      {
        label: DEFAULT_VALUES.saveLabel,
        onClick: (blocker: Blocker) => {
          uploadControllerRef?.current?.abort();
          void onSave({
            onCompleted: () => handleComplete(blocker),
            onError: (error) => handleError(error, blocker, 'save'),
          });
        },
      },
    ],
  };
};

export const getLogoutConfirmationOptions = ({
  openFeedbackModal,
  uploadControllerRef,
  handleLogout,
  resetFormManager,
  onSave,
  onDiscard,
}: ILogoutConfirmationOptions) => {
  const handleComplete = () => {
    handleLogout().catch((error: unknown) => {
      logError({ error });
    });
    resetFormManager();
  };

  const handleError = (error: Error, type: 'save' | 'discard') => {
    openFeedbackModal({
      type: 'error',
      title: `Failed to ${type} changes`,
      message: 'Please try again later.',
    });
    logError({ error });
  };

  return {
    message: DEFAULT_VALUES.message,
    cancelLabel: DEFAULT_VALUES.cancelLabel,
    discardLabel: DEFAULT_VALUES.discardLabel,
    onDiscard: async () => {
      uploadControllerRef?.current?.abort();
      await onDiscard({
        onCompleted: () => handleComplete(),
        onError: (error) => handleError(error, 'discard'),
      });
    },
    additionalOptions: [
      {
        label: DEFAULT_VALUES.saveLabel,
        onClick: () => {
          uploadControllerRef?.current?.abort();
          void onSave({
            onCompleted: () => handleComplete(),
            onError: (error) => handleError(error, 'save'),
          });
        },
      },
    ],
  };
};
