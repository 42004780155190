import { CDN_EXPIRATION_LOCAL_STORAGE } from 'constants/api';
import { axiosRestApi } from './axiosRestApi';

// backend cdn signed cookie request
export const callGetCdnSignedCookie = async () => {
  // cdn signed cookie base url
  try {
    await axiosRestApi
      .get<{ status: string; expirationTime: number }>('/getCdnSignedCookie')
      .then((response): void => {
        localStorage.setItem(
          CDN_EXPIRATION_LOCAL_STORAGE,
          response.data.expirationTime.toString(),
        );
      });
  } catch (err: any) {
    throw err;
  }
};
