import { LinearProgress, linearProgressClasses, styled } from '@mui/material';

import { colorByType, ToastTypeEnum } from '../toast.constants';

export const StyledLinearProgress = styled(LinearProgress)({
  marginTop: '16px',
  borderRadius: '5px',
  height: '6px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: 'rgba(255, 255, 255, 0.15)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    background: colorByType[ToastTypeEnum.LOADING],
    borderRadius: '5px',
  },
});
