import { ListItemButton, ListItemText } from '@mui/material';
import { colors } from 'style/colors';
import { Notification } from 'types/mongoose-types';

const primaryTextStyle = {
  color: colors.mainD,
};
const secondaryTextStyle = {
  color: colors.mainW,
};

interface INotificationActionItem {
  data: Notification;
  onClick: () => void;
}

const NotificationActionItem = ({ onClick, data }: INotificationActionItem) => {
  return (
    <ListItemButton onClick={onClick} sx={{ alignItems: 'flex-start' }}>
      <ListItemText
        primary={data.title}
        secondary={data.description}
        primaryTypographyProps={{
          variant: 'body1',
          sx: { fontWeight: data.read ? '100' : 'bold', ...primaryTextStyle },
        }}
        secondaryTypographyProps={{
          variant: 'body2',
          sx: { fontWeight: data.read ? '100' : 'bold', ...secondaryTextStyle },
        }}
      />
    </ListItemButton>
  );
};

export default NotificationActionItem;
