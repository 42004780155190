import { gql } from '@apollo/client';

export const GET_PATIENT_EXAM_DETAILS = gql`
  query Query($examId: MongoID!) {
    exam(_id: $examId) {
      _id
      examDate
      physician
      findings {
        highlight
        targets {
          _id
          code
          certainty
        }
        index
        timeStamp
        location
        frame
        thumbnail
        relevant
        userLabel
      }
      priority
      user {
        _id
        firstName
        lastName
        firebaseId
        institution {
          _id
        }
      }
      technology
      device
      media {
        fullVideo
        highlights
      }
      medicalNotes {
        _id
        user {
          firstName
          lastName
          _id
        }
        content
        creationDate
      }
      clinicalIndication
      status
      createdAt
    }
  }
`;

export const UPDATE_EXAM = gql`
  mutation Mutation(
    $updateExamId: MongoID!
    $updateExamRecord: UpdateByIdExamInput!
  ) {
    updateExam(_id: $updateExamId, record: $updateExamRecord) {
      record {
        _id
        examDate
        physician
        findings {
          targets {
            _id
            code
            certainty
          }
          index
          timeStamp
          location
          frame
          thumbnail
          relevant
          userLabel
        }
        priority
        user {
          firstName
          lastName
          firebaseId
          institution {
            _id
          }
        }
        technology
        media {
          fullVideo
          highlights
        }
        medicalNotes {
          _id
          user {
            _id
            firstName
            lastName
          }
          content
          creationDate
        }
        clinicalIndication
        status
        createdAt
      }
    }
  }
`;
