import { PATIENT_IDENTIFICATION_TYPE } from 'constants/patients';
import * as yup from 'yup';
import { FORM_ERROR_MESSAGE } from './formErrorMessages';
import { MAX_LENGTH } from './length';
import { EMAIL_REGEX, TEXT_FIELD_REGEX } from './regexValidators';

export const requiredTextField = yup
  .string()
  .trim()
  .max(MAX_LENGTH.TEXT, FORM_ERROR_MESSAGE.MAX)
  .matches(TEXT_FIELD_REGEX, FORM_ERROR_MESSAGE.INVALID_CHARACTERS)
  .required(FORM_ERROR_MESSAGE.REQUIRED);

export const requiredEmailField = yup
  .string()
  .trim()
  .max(MAX_LENGTH.EMAIL, FORM_ERROR_MESSAGE.MAX)
  .matches(EMAIL_REGEX, FORM_ERROR_MESSAGE.EMAIL)
  .required(FORM_ERROR_MESSAGE.REQUIRED);

export const textField = yup
  .string()
  .trim()
  .max(MAX_LENGTH.TEXT, FORM_ERROR_MESSAGE.MAX)
  .matches(TEXT_FIELD_REGEX, {
    message: FORM_ERROR_MESSAGE.INVALID_CHARACTERS,
    excludeEmptyString: true,
  })
  .nullable();

export const identificationTypeField = yup
  .string()
  .oneOf(
    Object.values(PATIENT_IDENTIFICATION_TYPE),
    FORM_ERROR_MESSAGE.INVALID_IDENTIFICATION_TYPE,
  );
