import { useResubmitExamModal } from 'components/exams/ResubmitExamModalContent/resubmitExamModalContent.hooks';
import { NotificationObject } from 'types/mongoose-types';
import NotificationActionItem from '../NotificationActionItem';

interface IResubmitExamContent {
  data: NotificationObject;
  onOpen: () => void;
  onCompleted: () => void;
}

const ResubmitExamContent = ({
  data,
  onOpen,
  onCompleted,
}: IResubmitExamContent) => {
  const { openResubmitModal } = useResubmitExamModal({
    onClick: onOpen,
    onCompleted,
  });

  return (
    <NotificationActionItem
      data={data}
      onClick={() =>
        openResubmitModal((data.examId as unknown as string) || '')
      }
    />
  );
};

export default ResubmitExamContent;
