import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { getCloudflareAuth } from 'config/cloudflareAuth.api';
import { CF_AUTH_COOKIE } from 'constants/api';
import { useFeedbackModal, useSession } from 'providers';
import { deleteCookie, getCookie } from 'utils/cookie';
import { logError } from 'utils/logError';
import { useAuthModalError } from './useAuthModalError';

/**
 * Custom hook for handling Cloudflare authentication.
 *
 * @param {boolean} triggerLogin - Flag indicating whether to trigger the login process automatically. Default is true.
 * @param {Function} successCallback - Callback function to be executed upon successful authentication.
 *
 * @returns {Object} An object containing the loading state.
 * @property {boolean} loading - Flag indicating whether the authentication process is in progress.
 */
export const useCloudflareAuth = (
  triggerLogin = true,
  successCallback?: () => void,
): { loading: boolean } => {
  const [loading, setLoading] = useState(false);
  const { displayAuthErrorModal, loading: verificationLoading } =
    useAuthModalError();
  const {
    updateExpiresIn,
    updateFirebaseId,
    user,
    firebaseId,
    enableCloudflareAutoLogin,
  } = useSession();
  const { openFeedbackModal } = useFeedbackModal();
  // verify if cloudflare cookie is available
  const cloudflareCookie = getCookie(CF_AUTH_COOKIE);

  const loginWithCloudflare = useCallback(async () => {
    try {
      const data = await getCloudflareAuth();
      updateFirebaseId(data.firebaseId);
      updateExpiresIn(data.expirationDate.toString());
      successCallback?.();
    } catch (error) {
      logError({ error });

      if (error instanceof AxiosError) {
        displayAuthErrorModal(error, 'warning');
      } else {
        // show cloudflare auth failure
        openFeedbackModal({
          type: 'warning',
          title: 'Invalid Cloudflare Credentials',
          message: 'Please sign in manually',
        });
      }
      // clean cloudflare cookie
      deleteCookie(CF_AUTH_COOKIE);
    } finally {
      setLoading(false);
    }
  }, [updateFirebaseId]);

  useEffect(() => {
    if (
      triggerLogin &&
      enableCloudflareAutoLogin &&
      cloudflareCookie &&
      !firebaseId &&
      !user
    ) {
      setLoading(true);
      void loginWithCloudflare();
    }
  }, [firebaseId, user, triggerLogin, cloudflareCookie]);

  return { loading: loading || verificationLoading };
};
