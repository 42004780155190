import { useSessionExpiration } from './sessionManager.hooks';

const SessionManager = () => {
  // Manage session expiration
  useSessionExpiration();

  return <></>;
};

export default SessionManager;
