import { gql } from '@apollo/client';

export const GET_USER_EXAMS = gql`
  query GetUserExams($user: MongoID!) {
    shallowExams(user: $user) {
      _id
      user {
        firstName
        lastName
        firebaseId
        institution {
          _id
        }
      }
      patient {
        _id
        firstName
        lastName
        avatar
        identificationType
        identificationNumber
      }
      technology
      examDate
      physician
      priority
      relevantFindingsCount
      status
      createdAt
    }
  }
`;

export const GET_EXAM_BY_ID = gql`
  query GetExam($examId: MongoID!) {
    exam(_id: $examId) {
      _id
      user {
        firstName
        lastName
        firebaseId
        institution {
          _id
        }
      }
      findings {
        highlight
        targets {
          _id
          code
          certainty
        }
        index
        timeStamp
        location
        frame
        thumbnail
        relevant
        userLabel
      }
      technology
      examDate
      physician
      priority
      status
      createdAt
      patient {
        _id
      }
    }
  }
`;
