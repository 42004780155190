import { styled } from '@mui/material';
import { ComponentProps } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { TOAST_DEFAULT_DURATION } from '../toast.constants';

export const StyledToastContainer = styled(ToastContainer)({
  '&&&.Toastify__toast-container': {
    width: '400px',
  },
  '.Toastify__toast': {
    background: '#0E1719',
    boxShadow:
      '0px 8px 10px 0px #00000033, 0px 6px 30px 0px #0000001F, 0px 16px 24px 0px #00000024',
    borderRadius: '8px',
    padding: '12px 16px 12px 16px',
    position: 'relative',
    '&:last-child': { marginBottom: '0px' },
  },
  '.Toastify__toast-body': {
    padding: '0px',
  },
  '.Toastify__progress-bar--wrp': {
    // If we use display: none, the toast will not automatically close
    height: '0px',
  },
});

type IToastContainerProps = ComponentProps<typeof StyledToastContainer>;

const CustomToastContainer = (props: IToastContainerProps) => {
  return (
    <StyledToastContainer
      transition={Slide}
      hideProgressBar
      closeButton={false}
      autoClose={TOAST_DEFAULT_DURATION}
      {...props}
    />
  );
};

export default CustomToastContainer;
