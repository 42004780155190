import { IModal } from 'components/system/Modal/modal.types';
import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

type TDefaultModalProps = Omit<IModal, 'children' | 'open' | 'handleClose'> & {
  handleClose?: IModal['handleClose'];
};

export interface IModalContext {
  openModal: boolean;
  handleCloseModal: () => void;
  handleOpenModal: (
    content: ReactElement,
    modalProps?: TDefaultModalProps,
  ) => void;
  content?: ReactElement;
  modalProps?: TDefaultModalProps;
}

export interface IModalProvider {
  children?: ReactNode;
}

const initialContextState = {
  openModal: false,
  handleCloseModal: () => null,
  handleOpenModal: () => null,
};

const ModalContext = createContext<IModalContext>(
  initialContextState as IModalContext,
);

export const useModal = (): IModalContext => useContext(ModalContext);

const ModalProvider = ({ children }: IModalProvider): ReactElement => {
  const [openModal, setOpenModal] = useState(false);
  const [content, setContent] = useState<ReactElement>();
  const [modalProps, setModalProps] = useState<TDefaultModalProps>();

  const handleCloseModal = useCallback(() => {
    if (setOpenModal) {
      setOpenModal(false);
    }
  }, [setOpenModal]);

  const handleOpenModal = useCallback(
    (newContent: ReactElement, newModalProps?: TDefaultModalProps) => {
      setOpenModal(true);
      setContent(newContent);
      setModalProps(newModalProps);
    },
    [setOpenModal, setContent],
  );

  return (
    <ModalContext.Provider
      value={{
        openModal,
        handleOpenModal,
        handleCloseModal,
        content,
        modalProps,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
