import { Box, IconButton, Modal, Typography, styled } from '@mui/material';

export const StyledModal = styled(Modal)({
  width: 'fit-content',
  maxWidth: '610px',
  minHeight: 'fit-content',
  maxHeight: '300px',
  margin: 'auto',
  outline: 'none',
  zIndex: 1301,
  '& .MuiModal-backdrop': {
    backdropFilter: 'blur(2px)',
  },
});

export const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: `0px 4px 14px ${theme.palette.primary.main}`,
  borderRadius: '5px',
  padding: '30px 60px 25px 60px',
  outline: 'none',
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  '> svg': {
    height: '45px',
    width: '45px',
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  paddingTop: '15px',
  paddingBottom: '15px',
  color: theme.palette.primary.main,
}));

export const StyledMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.texts.main,
  whiteSpace: 'pre-wrap',
  maxHeight: '100px',
  overflowY: 'auto',
  overflowX: 'hidden',
}));

interface IButtonsWrapper {
  oneBtnOnly: boolean;
}

export const ButtonsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'oneBtnOnly',
})<IButtonsWrapper>(({ oneBtnOnly }) => ({
  marginTop: '15px',
  display: 'flex',
  justifyContent: oneBtnOnly ? 'center' : 'space-between',
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'absolute',
  top: '10px',
  right: '10px',
}));
