import { PATIENT_IDENTIFICATION_TYPE } from 'constants/patients';
import dayjs from 'dayjs';

export const FORM_ERROR_MESSAGE = {
  REQUIRED: 'Required',
  MAX: 'Field must not exceed ${max} characters',
  MIN: 'Insert at least ${min} characters',
  EMAIL: 'Invalid email address',
  INVALID_DATE: 'Invalid date',
  INVALID_CHARACTERS: 'Only letters and numbers are allowed',
  INVALID_FILE_TYPE: (acceptedFiles: string) =>
    `Invalid file. Types allowed: ${acceptedFiles}`,
  MIN_DATE: 'Date must be after 1900',
  DATE_FROM_PAST: 'Date must be from the past',
  INVALID_PHONE_NUMBER: 'Invalid phone number',
  FILE_NAME_MAX_LENGTH: 'File name must not exceed 100 characters',
  INVALID_IDENTIFICATION_TYPE: `Must be one of: ${Object.values(
    PATIENT_IDENTIFICATION_TYPE,
  ).join(', ')}`,
};

export const MIN_DATE = new Date(1900, 0, 1, 0, 0, 0, 0);

export const getEndOfToday = () => dayjs(new Date()).endOf('day').toDate();
