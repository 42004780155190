import { Modal, styled } from '@mui/material';

export const StyledModal = styled(Modal)({
  maxWidth: '650px',
  height: '65%',
  maxHeight: '650px',
  margin: 'auto',
  outline: 'none',
  zIndex: 1300,
  '& .MuiModal-backdrop': {
    backdropFilter: 'blur(2px)',
  },
});
