import { Button, styled } from '@mui/material';

const OutlinedButton = styled(Button)(({ theme }) => ({
  '&&': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.main,
    boxShadow: `0px 1px 6px ${theme.palette.primary.main}`,
    border: `1px solid ${theme.palette.primary.main}`,
    margin: '10px',
  },
  ':hover': {
    boxShadow: `0px 1px 10px ${theme.palette.primary.main}`,
  },
  ':disabled': {
    opacity: 0.2,
  },
}));

export default OutlinedButton;
