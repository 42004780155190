import CloseIcon from '@mui/icons-material/Close';
import { Grid, ModalProps } from '@mui/material';
import { FC, MouseEventHandler } from 'react';

import { STATIC_ASSETS_URL } from 'constants/api';
import {
  CloseButton,
  ContentWrapper,
  CustomModal,
  IconWrapper,
} from './logoModal.styles';

interface ILogoModal extends Omit<ModalProps, 'open'> {
  openModal: boolean;
  handleCloseModal: MouseEventHandler<HTMLButtonElement>;
  disableClose?: boolean;
}

const LogoModal: FC<ILogoModal> = ({
  openModal,
  handleCloseModal,
  disableClose,
  children,
  ...rest
}) => (
  <CustomModal
    open={openModal}
    onClose={handleCloseModal}
    hideBackdrop
    {...rest}
  >
    <ContentWrapper container spacing={4}>
      <IconWrapper item xs={4}>
        <img src={`${STATIC_ASSETS_URL}/img/digestaid-R.svg`} alt="Digestaid" />
      </IconWrapper>
      <Grid item xs={7}>
        {children}
      </Grid>
      <Grid item xs={1}>
        {!disableClose && (
          <CloseButton onClick={handleCloseModal}>
            <CloseIcon />
          </CloseButton>
        )}
      </Grid>
    </ContentWrapper>
  </CustomModal>
);

export default LogoModal;
