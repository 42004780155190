import { STATIC_ASSETS_URL } from './api';

type TExamTechnologyId =
  | 'deepBowel'
  | 'deepCapsule'
  | 'giSpy'
  | 'ibdSmart'
  | 'smartCholangio'
  | 'smartCyst';

type TExamTechnologyLabel =
  | 'Deep Bowel'
  | 'Deep Capsule'
  | 'GI Spy'
  | 'IBD Smart'
  | 'Smart Cholangio'
  | 'Smart Cyst';

export interface IProduct {
  id: TExamTechnologyId;
  img: string;
  label: TExamTechnologyLabel;
  description: string;
  furtherDescription: string;
  devices: { id: string; label: string }[];
  models?: string[];
  hidden?: boolean;
  disabled?: boolean;
}

export interface IDevice {
  id: string;
  label: string;
  acceptedFiles?: string;
}

export const DEVICES: IDevice[] = [
  { id: 'colon1', label: 'PillCam™ Colon 1', acceptedFiles: '.gvf' },
  { id: 'colon2', label: 'PillCam™ Colon 2', acceptedFiles: '.gvf' },
  { id: 'crohn', label: 'PillCam™ Crohn', acceptedFiles: '.gvf' },
  { id: 'ets', label: 'Fujifilm EN-580T' },
  { id: 'ets2', label: 'Olympus EVIS X1 - Spiral', acceptedFiles: '.avi' },
  { id: 'ets3', label: 'Olympus EVIS X1', acceptedFiles: '.avi' },
  { id: 'ets4', label: 'Olympus EVIS X1 - Spiral', acceptedFiles: '.avi' },
  { id: 'ets5', label: 'Fujifilm VP-7000' },
  { id: 'ets6', label: 'Fujifilm (Guessed)' },
  { id: 'ets7', label: 'Fujifilm VP-7000' },
  { id: 'ets8', label: 'Fujifilm VP-7000' },
  { id: 'eus', label: 'Fujifilm SU-1' },
  { id: 'hrano', label: 'THD® ProctoStation' },
  { id: 'mirocam', label: 'MiroCam®', acceptedFiles: '.avi' },
  { id: 'olympus', label: 'Olympus EC-10®', acceptedFiles: '.wmv,.mp4' },
  { id: 'omom', label: 'OMOM® HD', acceptedFiles: '.avi' },
  { id: 'sb1', label: 'PillCam™ SB1', acceptedFiles: '.mpg,.avi' },
  { id: 'sb2', label: 'PillCam™ SB2', acceptedFiles: '.mpg,.avi' },
  { id: 'sb3', label: 'PillCam™ SB3', acceptedFiles: '.gvf,.mpg' },
  { id: 'spy', label: 'SpyGlass DS' },
  { id: 'kolplast', label: 'Kolplast KLP_210' },
];

export const PRODUCTS: IProduct[] = [
  {
    id: 'deepCapsule',
    img: `${STATIC_ASSETS_URL}/img/logos/deep-capsule.svg`,
    label: 'Deep Capsule',
    description:
      'Detect and differentiate endoscopic lesions with groundbreaking sensitivity and specificity.',
    furtherDescription:
      'Accurate stratification of the hemorrhagic potential of a wide range of lesions.',
    devices: DEVICES.filter((device) =>
      ['sb3', 'sb1', 'sb2', 'omom', 'olympus'].includes(device.id),
    ),
    models: ['deep_capsule', 'deep_capsule_light', 'deep_capsule_binary'],
    hidden: !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('deepCapsule'),
    disabled:
      !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('deepCapsule'),
  },
  {
    id: 'giSpy',
    img: `${STATIC_ASSETS_URL}/img/logos/gi-spy.svg`,
    label: 'GI Spy',
    description: 'Panendoscopic AI solution.',
    furtherDescription: 'Detect and diagnose lesions in all GI tract.',
    devices: DEVICES.filter((device) =>
      ['colon1', 'colon2', 'sb3', 'crohn', 'omom'].includes(device.id),
    ),
    hidden: !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('giSpy'),
    disabled: !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('giSpy'),
  },
  {
    id: 'ibdSmart',
    img: `${STATIC_ASSETS_URL}/img/logos/ibd-smart.svg`,
    label: 'IBD Smart',
    description:
      'Tailor-made AI solution for the detection and classification of ulcers and erosions.',
    furtherDescription:
      'Improved diagnosis and endoscopic evaluation of small intestine and colon mucosal injury and inflammation.',
    devices: DEVICES.filter((device) =>
      ['colon1', 'colon2', 'sb3', 'crohn'].includes(device.id),
    ),
    hidden: !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('ibdSmart'),
    disabled: !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('ibdSmart'),
  },
  {
    id: 'smartCholangio',
    img: `${STATIC_ASSETS_URL}/img/logos/smart-cholangio.svg`,
    label: 'Smart Cholangio',
    description:
      'Optimized detection and characterization of biliary strictures.',
    furtherDescription: 'Accurate cholangiocarcinoma detection.',
    devices: DEVICES.filter((device) => ['spy'].includes(device.id)),
    hidden:
      !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('smartCholangio'),
    disabled:
      !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('smartCholangio'),
  },
  // TODO - replace smart cyst by smart pancreas
  {
    id: 'smartCyst',
    img: `${STATIC_ASSETS_URL}/img/logos/smart-cyst.svg`,
    label: 'Smart Cyst',
    description: 'Optimized detection and classification of pancreatic cysts.',
    furtherDescription:
      'Accurate differentiation between pancreatic cysts with distinct malignant potential.',
    devices: DEVICES.filter((device) => ['eus'].includes(device.id)),
    hidden: !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('smartCyst'),
    disabled: !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('smartCyst'),
  },
  {
    id: 'deepBowel',
    img: `${STATIC_ASSETS_URL}/img/logos/deep-bowel.svg`,
    label: 'Deep Bowel',
    description:
      'Exclusive AI solution for antegrade and retrograde Device-assisted enteroscopy.',
    furtherDescription:
      'Accurate detection of lesions throughout the gastrointestinal tract.',
    devices: DEVICES.filter((device) =>
      ['ets', 'ets2', 'ets3', 'ets4', 'ets5', 'ets6', 'ets7', 'ets8'].includes(
        device.id,
      ),
    ),
    hidden: !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('deepBowel'),
    disabled: !process.env.REACT_APP_AVAILABLE_PRODUCTS?.includes('deepBowel'),
  },
];

export const PRODUCTS_TO_SHOW = PRODUCTS.filter((product) => !product.hidden);

export const DEEP_CAPSULE_TARGET = {
  P3: 'Blood / Hematic residues',
  P1E: 'Erosions',
  P1U: 'Ulcers - Low Hemorrhagic Potential',
  P2U: 'Ulcers - High Hemorrhagic Potential',
  P1PE: 'Vascular lesions - Low Hemorrhagic Potential (Redspots)',
  P2V: 'Vascular lesions - High Hemorrhagic Potential',
  P1PR: 'Protruding lesions - Low Hemorrhagic Potential',
  P2PR: 'Protruding Lesions - High Hemorrhagic Potential',
  P0X: 'Xanthelasmas',
  P0L: 'Lymphagiectasia / Nodular Lymphoid',
} as const;

export const DEEP_CAPSULE_LIGHT_TARGET = {
  BL: 'Blood / Hematic residues',
  EU: 'Ulcers / Erosions',
  PR: 'Protruding lesions',
  V: 'Vascular lesions',
  XL: 'Xanthelasmas / Lymphagiectasias',
} as const;

export const DEEP_CAPSULE_BINARY_TARGET = {
  LESION: 'Lesion',
} as const;

export const DEEP_CAPSULE_MODELS = {
  MAIN: 'deep_capsule',
  LIGHT: 'deep_capsule_light',
  BINARY: 'deep_capsule_binary',
} as const;

export const DEEP_CAPSULE_MODEL =
  process.env.REACT_APP_DEEP_CAPSULE_MODEL ?? DEEP_CAPSULE_MODELS.MAIN;

// TODO Smart Cholangio
// const SMART_COLANGIO_TARGET = {
//   Benign: 'B',
//   Malignant: 'M',
// };

export const VALIDATION_ENABLED =
  process.env.REACT_APP_VALIDATION_ENABLED === 'true';

export const FILTER_BEFORE_PREDICTION =
  process.env.REACT_APP_FILTER_BEFORE_PREDICTION === 'true';

export const EVALUATE_CLEANSING =
  process.env.REACT_APP_EVALUATE_CLEANSING === 'true';
