import { toast } from 'react-toastify';
import { Toast } from '..';
import {
  IToastProps,
  TDismissToast,
  TDisplayToast,
  TUpdateToast,
} from '../toast.types';

export const displayToast: TDisplayToast = (props, toastOptions) =>
  toast((toastProps: IToastProps) => <Toast {...toastProps} />, {
    ...toastOptions,
    data: props,
  });

export const updateToast: TUpdateToast = (toastId, props, toastOptions) =>
  toast.update(toastId, { ...toastOptions, data: props });

export const dismissToast: TDismissToast = (toastId) => toast.dismiss(toastId);
