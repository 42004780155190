import { useReuploadExamModal } from 'components/exams/ReuploadExamModalForm/reuploadExamModalForm.hooks';
import { Notification } from 'types/mongoose-types';
import NotificationActionItem from '../NotificationActionItem';

interface IUploadExamContent {
  data: Notification;
  onOpen: () => void;
  onCompleted: () => void;
}

const UploadExamContent = ({
  data,
  onOpen,
  onCompleted,
}: IUploadExamContent) => {
  const { openReuploadModal } = useReuploadExamModal({
    onClick: onOpen,
    onCompleted,
  });

  return (
    <NotificationActionItem
      data={data}
      onClick={() =>
        openReuploadModal((data?.examId as unknown as string) || '')
      }
    />
  );
};

export default UploadExamContent;
