import { styled } from '@mui/material';

import { ActionButton } from 'components/system/buttons';
import { colorByType, TToastType } from '../toast.constants';

export const StyledButton = styled(ActionButton, {
  shouldForwardProp: (prop) => prop !== 'toastType',
})(({ toastType }: { toastType: TToastType }) => ({
  fontSize: '15px',
  fontWeight: '700',
  color: colorByType[toastType],
  padding: '6px 16px',
}));
