import { Box, styled } from '@mui/material';
import LogoModal from 'components/layout/LogoModal/LogoModal';

export const ContentWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LogoModalDarkBackdrop = styled(LogoModal)({
  '& .MuiModal-backdrop': {
    backdropFilter: 'blur(2px)',
  },
});
