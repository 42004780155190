import { PatientObject } from 'types/mongoose-types';

export const formatPatientName = (
  entity: Pick<
    PatientObject,
    'firstName' | 'lastName' | 'identificationNumber'
  >,
) =>
  `${entity.firstName ?? ''} ${entity.lastName ?? ''} - ${
    entity.identificationNumber ?? ''
  }`;
