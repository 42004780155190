import {
  List,
  ListItemButton,
  ListItemText,
  alpha,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from 'style/colors';

export const StyledList = styled(List)({
  width: '198px',
  alignItems: 'center',
  backgroundColor: '#0A0D0E',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '2px',
  padding: '2px',
  margin: 1,
  boxShadow: `0px 64px 64px -32px ${alpha('#290F00', 0.56)}`,
  border: `0.5px solid ${alpha('#75F9FB', 0.3)}`,
});

export const StyledItemButton = styled(ListItemButton)({
  padding: '12px 16px 12px 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 'inherit',
});

export const StyledItemText = styled(ListItemText)({
  textAlign: 'right',
  margin: 0,
});

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  width: '100%',
  borderRadius: '12px',
  color: alpha('#B1CBCF', 0.7),
  fontSize: '12px',
  fontWeight: 400,
  transitionProperty: 'color, background',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'linear',
  '&:hover': {
    fontWeight: 500,
    color: '#75F9FB',
    background: alpha('#73F6F9', 0.06),
  },
  '&:active': {
    background: '#73F6F9',
    color: colors.black2,
  },
});
