import { FormControlLabel, styled } from '@mui/material';

const CustomFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'child',
})<{ child?: boolean }>(({ theme, child }) => ({
  color: child ? theme.palette.texts.main : theme.palette.primary.main,
  '& .MuiRadio-root': {
    color: theme.palette.primary.main,
  },
  '.MuiFormControlLabel-label': {
    fontSize: 12,
  },
  '&.Mui-disabled': {
    opacity: 0.4,
    cursor: 'not-allowed',
    '& .MuiRadio-root': {
      color: theme.palette.primary.main,
    },
    '.MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
    },
  },
}));

export default CustomFormControlLabel;
