import { useApolloClient } from '@apollo/client';
import { useSession } from 'providers';
import { useSessionExpiryModal } from 'providers/SessionExpiryModalProvider/SessionExpiryModalProvider';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logError } from 'utils/logError';
import { isSessionExpired } from 'utils/sessionExpiry';

// 30 seconds
const EXPIRATION_CHECK_FREQUENCY = 1000 * 30;

export const useSessionExpiration = () => {
  const apolloClient = useApolloClient();
  const { user, clearSessionUser, getExpiresIn, firebaseId, logoutInProgress } =
    useSession();
  const { isModalOpen, openSessionExpiryModal } = useSessionExpiryModal();
  const { pathname } = useLocation();
  const expiresIn = getExpiresIn();
  const isLoginPage = pathname === '/';

  // If the user is not set after 5 seconds, show the sign in modal
  useEffect(() => {
    if (!user && !isLoginPage && !logoutInProgress) {
      const timeoutId = setTimeout(() => openSessionExpiryModal(), 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [user]);

  // Check if session is expired on a set interval
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isModalOpen && isSessionExpired(expiresIn) && firebaseId) {
        if (isLoginPage) {
          clearSessionUser().catch((error: unknown) => {
            logError({ error });
          });
        }
        openSessionExpiryModal();
        void apolloClient.clearStore();
      }
    }, EXPIRATION_CHECK_FREQUENCY);

    return () => {
      clearInterval(intervalId);
    };
  }, [firebaseId, expiresIn, isModalOpen, isLoginPage]);

  // Refetch active queries when user logs back in
  // after session expiration
  useEffect(() => {
    if (user) {
      void apolloClient.reFetchObservableQueries();
    }
  }, [user]);
};
