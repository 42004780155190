import { ModalCloseButton, ModalFooter, ModalHeader } from '.';
import { SubmitButton } from '../buttons';
import { StyledModal, StyledModalContent } from './components';
import { IModal } from './modal.types';

const Modal = ({
  handleCancel,
  handleClose,
  handleSubmit,
  cancelLabel,
  submitLabel = 'CONFIRM',
  open,
  children,
  title,
  ...modalProps
}: IModal) => {
  return (
    <StyledModal {...modalProps} open={open}>
      <StyledModalContent>
        <ModalCloseButton handleClose={handleClose} />
        <ModalHeader title={title} />
        {children}
        <ModalFooter
          handleCancel={handleCancel || handleClose}
          cancelLabel={cancelLabel}
        >
          {!!handleSubmit && (
            <SubmitButton onClick={handleSubmit} variant="contained">
              {submitLabel}
            </SubmitButton>
          )}
        </ModalFooter>
      </StyledModalContent>
    </StyledModal>
  );
};

export default Modal;
