import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, styled } from '@mui/material';

const UploadButton = styled(LoadingButton)(({ theme }) => ({
  '&&': {
    color: theme.palette.background.main,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0px 1px 3px ${theme.palette.primary.main}`,
    margin: '10px',
    borderRadius: 0,
  },
  ':hover': {
    boxShadow: `0px 1px 10px ${theme.palette.primary.main}`,
  },
  ':disabled': {
    // Prevents blinking when loading state is toggled
    transition: 'all 0s',
    opacity: 0.2,
  },
  '&.MuiLoadingButton-loading': {
    opacity: 1,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    boxShadow: `0px 1px 3px ${alpha(theme.palette.primary.main, 0.2)}`,

    '& .MuiCircularProgress-root': {
      color: theme.palette.primary.main,
    },
  },
}));

export default UploadButton;
