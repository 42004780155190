import { Popover, PopoverProps } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogout } from 'components/auth/hooks/useLogout';
import { STATIC_ASSETS_URL } from 'constants/api';
import PopoverItem from './PopoverItem';
import { StyledList } from './status.styles';

interface IStatusMenu extends Pick<PopoverProps, 'anchorEl' | 'onClose'> {
  isOpen: boolean;
}

const StatusMenu: FC<IStatusMenu> = ({ anchorEl, isOpen, onClose }) => {
  const navigate = useNavigate();

  const { logout } = useLogout();

  const createOnClickHandler = (proceedMethod: () => void) => {
    return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      onClose?.(e, 'backdropClick');
      proceedMethod();
    };
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          sx: {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <StyledList>
        <PopoverItem
          to="/app/my-account"
          onClick={createOnClickHandler(() => {
            navigate('/app/my-account');
          })}
          iconSrc={`${STATIC_ASSETS_URL}/img/icons/account_v1.svg`}
          text="My Account"
        />
        <PopoverItem
          to="/app/about"
          text="About"
          iconSrc={`${STATIC_ASSETS_URL}/img/icons/about_v1.svg`}
          onClick={createOnClickHandler(() => {
            navigate('/app/about');
          })}
        />
        <PopoverItem
          to="/"
          onClick={createOnClickHandler(logout)}
          iconSrc={`${STATIC_ASSETS_URL}/img/icons/logout_v1.svg`}
          text="Logout"
        />
      </StyledList>
    </Popover>
  );
};

export default StatusMenu;
