import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface IFormManagerProvider {
  children?: ReactNode;
}

export type TDirtyForm = 'default' | 'saveable' | null;

export interface IConfirmationProps {
  message: string;
  cancelLabel: string;
  discardLabel: string;
  onDiscard: () => Promise<void>;
  additionalOptions: {
    label: string;
    onClick: () => void;
  }[];
}

export interface IFormManagerContext {
  dirtyFormRef: MutableRefObject<TDirtyForm>;
  confirmationProps: IConfirmationProps | null;
  setConfirmationProps: (value: IConfirmationProps | null) => void;
  uploadControllerRef: MutableRefObject<AbortController | null>;
  resetFormManager: () => void;
}

const FormManagerContext = createContext<IFormManagerContext>(
  {} as IFormManagerContext,
);

export const useFormManager = (): IFormManagerContext =>
  useContext(FormManagerContext);

const FormManagerProvider = ({
  children,
}: IFormManagerProvider): JSX.Element => {
  // form dirty state
  const dirtyFormRef = useRef<TDirtyForm>(null);
  const [confirmationProps, setConfirmationProps] =
    useState<IConfirmationProps | null>(null);

  const uploadControllerRef = useRef<AbortController | null>(
    new AbortController(),
  );

  const resetFormManager = () => {
    dirtyFormRef.current = null;
    setConfirmationProps(null);
    uploadControllerRef.current = new AbortController();
  };

  // user context values
  const values = useMemo<IFormManagerContext>(
    () => ({
      dirtyFormRef,
      confirmationProps,
      setConfirmationProps,
      uploadControllerRef,
      resetFormManager,
    }),
    [
      dirtyFormRef,
      confirmationProps,
      setConfirmationProps,
      uploadControllerRef,
      resetFormManager,
    ],
  );

  return (
    <FormManagerContext.Provider value={values}>
      {children}
    </FormManagerContext.Provider>
  );
};

export default FormManagerProvider;
