import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import { FC } from 'react';

import Notifications from 'components/notifications/Notifications';
import Search from 'components/search/Search';
import Status from 'components/status/Status';
import { useSession } from 'providers';
import {
  CustomAppBar,
  CustomToolbar,
  SearchBar,
  SearchIconWrapper,
  StyledTitle,
} from './appBar.styles';

interface IAppBar {
  title: string;
  open: boolean;
}

const AppBar: FC<IAppBar> = ({ title, open }) => {
  const { user } = useSession();

  if (!user) return null;

  return (
    <CustomAppBar open={open}>
      <CustomToolbar open={open}>
        <StyledTitle variant="h6" noWrap>
          {title}
        </StyledTitle>

        <SearchBar>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Search />
        </SearchBar>

        <Box>
          <Notifications />
          <Status />
        </Box>
      </CustomToolbar>
    </CustomAppBar>
  );
};

export default AppBar;
