import { STATIC_ASSETS_URL } from 'constants/api';

export const TOAST_DEFAULT_DURATION = 5000;

export const ToastTypeEnum = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  LOADING: 'loading',
} as const;

export type TToastType = (typeof ToastTypeEnum)[keyof typeof ToastTypeEnum];

export const toastIconMap: Record<TToastType, { src: string; alt: string }> = {
  success: {
    src: `${STATIC_ASSETS_URL}/img/icons/check_circle_v1.svg`,
    alt: 'success',
  },
  info: {
    src: `${STATIC_ASSETS_URL}/img/icons/info_v1.svg`,
    alt: 'info',
  },
  error: {
    src: `${STATIC_ASSETS_URL}/img/icons/cancel_v1.svg`,
    alt: 'error',
  },
  loading: {
    src: `${STATIC_ASSETS_URL}/img/icons/loading_info_v1.svg`,
    alt: 'loading',
  },
};

export const gradientColorByType: Record<TToastType, string> = {
  success: 'rgba(0, 237, 81, 0.12)',
  info: 'rgba(255, 212, 38, 0.11)',
  error: 'rgba(240, 66, 72, 0.13)',
  loading: 'rgba(115, 246, 249, 0.11)',
};

export const colorByType: Record<TToastType, string> = {
  success: '#00DF80',
  info: '#FFD21E',
  error: '#F04248',
  loading: '#73F6F9',
};

export const closeButtonIconMap: Record<TToastType, string> = {
  success: `${STATIC_ASSETS_URL}/img/icons/close_success_v1.svg`,
  info: `${STATIC_ASSETS_URL}/img/icons/close_info_v1.svg`,
  error: `${STATIC_ASSETS_URL}/img/icons/close_error_v1.svg`,
  loading: `${STATIC_ASSETS_URL}/img/icons/close_loading_v1.svg`,
};
