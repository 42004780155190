import { IconButton, styled } from '@mui/material';

const AppBarIconButton = styled(IconButton)({
  marginRight: '20px',
  '& img': {
    width: '25px',
    height: '25px',
  },
});

export default AppBarIconButton;
