import type {} from '@mui/lab/themeAugmentation';
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  createTheme,
} from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import React from 'react';

import {
  IGallery,
  TGalleryClassKey,
} from 'components/system/Gallery/gallery.types';
import { colors, gradients } from './colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle?: React.CSSProperties;
  }

  interface Palette {
    colors: typeof colors;
    gradients: typeof gradients;
    ctaLow: Palette['primary'];
    ctaMedium: Palette['primary'];
    ctaHigh: Palette['primary'];
    texts: Palette['primary'];
    subtitle: Palette['primary'];
    type: Palette['primary'];
    lateralBg: Palette['primary'];
    title: Palette['primary'];
    dashboardCat: Palette['primary'];
    iconText: Palette['primary'];
    gradient: Palette['primary'];
  }
  interface PaletteOptions {
    colors: typeof colors;
    gradients: typeof gradients;
    ctaLow: PaletteOptions['primary'];
    ctaMedium: PaletteOptions['primary'];
    ctaHigh: PaletteOptions['primary'];
    texts: PaletteOptions['primary'];
    subtitle: PaletteOptions['primary'];
    type: PaletteOptions['primary'];
    lateralBg: PaletteOptions['primary'];
    title: PaletteOptions['primary'];
    dashboardCat: PaletteOptions['primary'];
    iconText: PaletteOptions['primary'];
    gradient: PaletteOptions['primary'];
  }

  interface ComponentsPropsList {
    GalleryContainer: IGallery<object>;
  }
  interface ComponentNameToClassKey {
    GalleryContainer: TGalleryClassKey;
  }
  interface Components {
    GalleryContainer?: {
      defaultProps?: ComponentsProps['GalleryContainer'];
      styleOverrides?: ComponentsOverrides['GalleryContainer'];
      variants?: ComponentsVariants['GalleryContainer'];
    };
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    main: string;
    secondary: string;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle: true;
  }
}

const theme = (mode = 'dark') =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1600,
      },
    },
    palette: {
      common: {
        white: 'rgb(255,255,255)',
        black: 'rgb(0,0,0)',
      },
      colors,
      gradients,
      primary: {
        main: mode === 'dark' ? colors.mainD : colors.mainW,
      },
      ctaLow: {
        main: colors.green,
      },
      ctaMedium: {
        main: colors.orange,
      },
      ctaHigh: {
        main: colors.red,
      },
      texts: {
        main: colors.grey1,
      },
      subtitle: {
        main: colors.grey2,
      },
      type: {
        main: colors.grey3,
      },
      lateralBg: {
        main: mode === 'dark' ? colors.black1 : colors.white2,
      },
      title: {
        main: mode === 'dark' ? colors.white1 : colors.black1,
      },
      dashboardCat: {
        main: mode === 'dark' ? colors.black1 : colors.white2,
      },
      background: {
        main: mode === 'dark' ? colors.black3 : colors.grey4,
        secondary: mode === 'dark' ? colors.black2 : colors.white1,
      },
      iconText: {
        main: mode === 'dark' ? colors.mainD : colors.mainW,
      },
      gradient: {
        main: mode === 'dark' ? gradients.gradientD : gradients.gradientW,
      },
    },
    typography: {
      fontFamily: '"Roboto", "Arial", sans-serif',
      body1: {
        fontSize: 14,
        fontFamily: '"Roboto", "Arial", sans-serif',
      },
      body2: {
        fontSize: 14,
        fontFamily: '"Roboto", "Arial", sans-serif',
        color: mode === 'dark' ? colors.mainD : colors.mainW,
      },
      h1: {
        fontSize: 45,
        fontFamily: '"Roboto", "Arial", sans-serif',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: 30,
        fontFamily: '"Roboto", "Arial", sans-serif',
        fontWeight: 'bold',
        marginBottom: 10,
      },
      h3: {
        fontSize: 20,
        fontFamily: '"Roboto", "Arial", sans-serif',
        fontWeight: 'bold',
        color: mode === 'dark' ? colors.white1 : colors.black1,
      },
      h4: {
        fontSize: 18,
        fontFamily: '"Roboto", "Arial", sans-serif',
        color: mode === 'dark' ? colors.white1 : colors.black1,
      },
      h5: {
        fontSize: 14,
        fontFamily: '"Roboto", "Arial", sans-serif',
      },
      h6: {
        fontSize: 13,
        fontFamily: '"Roboto", "Arial", sans-serif',
      },
      subtitle: {
        fontSize: 12,
        fontFamily: '"Roboto", "Arial", sans-serif',
      },
      caption: {
        fontSize: 10,
        fontFamily: '"Roboto", "Arial", sans-serif',
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            color: 'white',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid #73f5f8',
            marginBottom: 0,
          },
          underline: {
            '& :after': {
              borderBottomColor: '#73f5f8',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover fieldset': {
              borderColor: 'yellow',
            },
            '& .Mui-focused fieldset': {
              borderColor: 'green',
            },
          },
          input: {
            color: colors.grey2,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: { color: '#73f5f8' },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: { color: colors.grey2 },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: { color: '#73f5f8' },
        },
      },
      MuiImageListItemBar: {
        styleOverrides: {
          titleWrapBelow: {
            padding: '20px 10px',
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: colors.black2,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            borderBottom: '1px solid #2B2C2E',
          },
          indicator: {
            backgroundColor: '#73f5f8',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: '#2B2C2E',
            textTransform: 'none',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderCollapse: 'separate',
            borderSpacing: '0 2px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: 'none',
            boxSizing: 'border-box',
            fontSize: 16,
            maxHeight: 50,
          },
          stickyHeader: {
            backgroundColor: 'transparent',
            fontSize: 20,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            boxSizing: 'border-box',
            maxWidth: 50,
          },
        },
      },
      GalleryContainer: {
        styleOverrides: {
          root: {
            height: 'auto',
            width: 460,
          },
        },
        variants: [
          {
            props: { variant: 'tall' },
            style: {
              height: 600,
            },
          },
          {
            props: { variant: 'short' },
            style: {
              height: 200,
            },
          },
        ],
      },
    },
  });

export default theme;
