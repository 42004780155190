import { ListItemButton } from '@mui/material';
import { ListItemProps } from '@mui/material/ListItem';
import { FC, forwardRef, useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

type TListItemLink = ListItemProps & {
  to?: string;
};

const ListItemLink: FC<TListItemLink> = ({
  children,
  to,
  onClick,
  ...rest
}) => {
  const renderLink = useMemo(() => {
    if (to) {
      return forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
        (itemProps, ref) => {
          return (
            <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />
          );
        },
      );
    }

    return 'li';
  }, [to]);

  return (
    <ListItemButton onClick={onClick} component={renderLink} {...rest}>
      {children}
    </ListItemButton>
  );
};

export default ListItemLink;
