import { Grid, IconButton, Modal, styled } from '@mui/material';

export const CustomModal = styled(Modal)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  maxWidth: '675px',
  maxHeight: '610px',
  display: 'flex',
  boxShadow: `0px 0px 25px ${theme.palette.primary.main}`,
  margin: 'auto',
  padding: '10px',
  outline: 'none',
}));

export const ContentWrapper = styled(Grid)({ outline: 'none' });

export const IconWrapper = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  '& > img': {
    height: '75px',
    transform: 'rotate(-90deg) translate(-50%, 0)',
  },
});

export const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: '-15px',
}));
