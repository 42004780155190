import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Link, styled } from '@mui/material';

const Page404Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  color: theme.palette.subtitle.main,
}));

const MainHeading = styled('h1')(({ theme }) => ({
  fontSize: '4rem',
  fontWeight: 600,
  color: theme.palette.primary.main,
}));

const SubHeading = styled('h2')(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  color: theme.palette.texts.main,
  marginBottom: '2rem',
}));

const SUPPORT_EMAIL =
  process.env.REACT_APP_SUPPORT_EMAIL ?? 'support@digestaid.health';

const Page404 = () => {
  return (
    <Page404Wrapper>
      <WarningAmberIcon fontSize="large" />
      <MainHeading>404</MainHeading>
      <SubHeading>Page not found</SubHeading>
      <h3>If you think this is an error please contact our support team at:</h3>
      <h3>
        <Link
          sx={{ color: 'inherit', textDecoration: 'none' }}
          href={`mailto:${SUPPORT_EMAIL}`}
        >
          {SUPPORT_EMAIL}
        </Link>
      </h3>
    </Page404Wrapper>
  );
};

export default Page404;
