import { Box } from '@mui/material';

import { OutlinedButton } from '../buttons';
import { ButtonsWrapper } from './components';
import { IModalFooter } from './modal.types';

const ModalFooter = ({
  cancelLabel = 'CANCEL',
  handleCancel,
  children,
  ...restProps
}: IModalFooter) => {
  return (
    <Box {...restProps}>
      <ButtonsWrapper oneBtnOnly={!children}>
        <OutlinedButton key="cancel" variant="outlined" onClick={handleCancel}>
          {cancelLabel}
        </OutlinedButton>
        {children}
      </ButtonsWrapper>
    </Box>
  );
};

export default ModalFooter;
