export const API_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:4000';

export const WS_URL = process.env.REACT_APP_WS_URL ?? 'ws://localhost:4000';

export const PREDICTION_API_URL =
  process.env.REACT_APP_PREDICTION_API_URL ??
  'http://localhost:4000/prediction';

export const STATIC_ASSETS_URL =
  process.env.REACT_APP_PUBLIC_STATIC_ASSETS_URL ??
  `${process.env.PUBLIC_URL}/assets`;

export const PRIVATE_ASSETS_URL =
  process.env.REACT_APP_PRIVATE_STATIC_ASSETS_URL ?? '/static';

export const CF_AUTH_COOKIE = 'CF_Authorization';

export const FB_AUTH_COOKIE = 'FB_Authorization';
export const FB_AUTH_EXPIRATION_LOCAL_STORAGE = 'FB_Auth_Expiration';
export const FB_ID_LOCAL_STORAGE = 'FB_Id';

export const USER_NAME_LOCAL_STORAGE = 'User_Name';

export const CDN_SIGNED_COOKIE = 'Cloud-CDN-Cookie';
export const CDN_EXPIRATION_LOCAL_STORAGE = 'Cloud-CDN-Expiration';
