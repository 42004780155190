import { Button, styled } from '@mui/material';

const SubmitButton = styled(Button)(({ theme }) => ({
  '&&': {
    color: theme.palette.background.main,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0px 0px 14px ${theme.palette.primary.main}`,
    margin: '10px',
  },
  ':hover': {
    boxShadow: `0px 0px 20px ${theme.palette.primary.main}`,
  },
  ':disabled': {
    opacity: 0.2,
  },
}));

export default SubmitButton;
