import { Badge } from '@mui/material';
import { FC } from 'react';

import AppBarIconButton from 'components/system/AppBar/AppBarIconButton';
import { STATIC_ASSETS_URL } from 'constants/api';
import { INotificationButton } from './notifications.types';

const NotificationsButton: FC<INotificationButton> = ({
  onClick,
  newNotifications,
}) => {
  return (
    <AppBarIconButton onClick={onClick} edge="start">
      <Badge badgeContent={newNotifications} color="primary">
        <img
          src={`${STATIC_ASSETS_URL}/img/icons/bel.svg`}
          alt={`Notifications Icon`}
        />
      </Badge>
    </AppBarIconButton>
  );
};

export default NotificationsButton;
