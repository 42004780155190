import axios from 'axios';

import { PREDICTION_API_URL } from 'constants/api';
import { logError } from 'utils/logError';
import { IUploadExamFiles } from './examDropZone.types';

/**
 * Uploads exam files to the prediction API.
 * @param files - The files to upload.
 * @param examId - The exam ID.
 * @param device - The exam's device.
 * @param institutionId - The institution ID.
 * @param uploadControllerRef - The reference to the upload controller.
 */
export const onUploadSubmit = async ({
  files,
  examId,
  device,
  institutionId,
  uploadControllerRef,
}: IUploadExamFiles) => {
  const file = files[0];

  if (!file) {
    logError({ error: 'FILE NOT FOUND!!!', ignoreSentry: true });
    return;
  }

  try {
    // GET SIGNED URL
    const signedURLRequest = axios.create({
      baseURL: `${PREDICTION_API_URL}/upload`,
      headers: {
        'Content-type': 'application/json',
        'Exam-id': examId,
        Device: device,
        'Institution-id': institutionId,
        'File-Name': file.name,
      },
      withCredentials:
        process.env.REACT_APP_AXIOS_INCLUDE_CREDENTIALS === 'true',
    });

    // request new upload url
    await signedURLRequest.get('/get_signed_url').then((response) => {
      // set generated URL as upload URL request
      const baseURL = response.data as string;

      const uploadRequest = axios.create({
        // use baseURL (generated by get_signed_url request)
        baseURL,
      });

      return uploadRequest.put('', file, {
        signal: uploadControllerRef?.current?.signal,
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });
    });
  } catch (err) {
    if (
      err instanceof Error &&
      (err.name === 'AbortError' || err.name === 'CanceledError')
    ) {
      throw new Error('Upload canceled');
    }
    throw new Error(JSON.stringify(err));
  }
};
