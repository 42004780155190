import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  alpha,
  styled,
} from '@mui/material';

import { drawerWidth } from '../PageBase/PageBase';

export const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  paddingTop: 55,
  backgroundColor: theme.palette.background.main,
  marginLeft: 100,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `calc(50px + ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const CustomToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'transparent',
  marginLeft: 150,
  transition: theme.transitions.create(['margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `calc(50px + ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.title.main,
  fontWeight: 'bold',
  fontSize: '45px',
}));

export const SearchBar = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 23,
  backgroundColor: '#0B0D10',
  color: theme.palette.subtitle.main,
  '&:hover': {
    backgroundColor: alpha('#0B0D10', 0.85),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '500px!important',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
