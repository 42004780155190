import { DialogContent, styled } from '@mui/material';

export const StyledModalContent = styled(DialogContent)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.main,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: `0px 4px 14px ${theme.palette.primary.main}`,
  borderRadius: '5px',
  padding: '30px 60px',
  outline: 'none',
  gap: '20px',
}));
