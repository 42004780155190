import { ApolloError, ServerError } from '@apollo/client';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Link } from '@mui/material';
import { AxiosError } from 'axios';
import { Component, ReactNode } from 'react';

import { SUPPORT_EMAIL } from 'constants/info';
import { logError } from './logError';

type TErrorType = Error | ApolloError | ServerError | AxiosError;

class ErrorBoundary extends Component<
  { children: ReactNode },
  { error: boolean | string }
> {
  constructor(props: { children: ReactNode }) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error: TErrorType) {
    // next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: TErrorType, errorInfo: any) {
    logError({ error });
    logError({ error: errorInfo });
  }

  render() {
    if (this.state.error) {
      // Create a custom fallback UI here
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          sx={{ color: '#757E8D' }}
        >
          <WarningAmberIcon fontSize="large" />
          <h1>There seems to be a problem. Please refresh the page.</h1>
          <h2>If this error persists please contact our support team at:</h2>
          <h2>
            <Link
              sx={{ color: 'inherit', textDecoration: 'none' }}
              href={`mailto:${SUPPORT_EMAIL}`}
            >
              {SUPPORT_EMAIL}
            </Link>
          </h2>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
