import { useQuery } from '@apollo/client';
import ExamDropZone from 'components/exams/ExamDropZone/ExamDropZone';
import { getValidationSchema } from 'components/exams/ExamDropZone/examDropzone.yup';
import { useRemoveExam } from 'components/pages/CreateExam/ExamPanel/examPanel.hooks';
import ContainerLoading from 'components/system/ContainerLoading/ContainerLoading';
import { OutlinedButton, SubmitButton } from 'components/system/buttons';
import { DEVICES } from 'constants/products';
import { useFeedbackModal } from 'providers';
import { useForm } from 'react-hook-form';
import { ExamObject } from 'types/mongoose-types';
import { logError } from 'utils/logError';
import useYupValidationResolver from 'utils/useYupValidationResolver';
import { useUpdateExam } from '../examForms/examForms.hooks';
import { GET_EXAM_FOR_REUPLOAD } from './reuploadExamModalForm.api';
import { handleUploadSubmit } from './reuploadExamModalForm.helpers';
import { StyledFooter } from './reuploadExamModalForm.styles';
import { IReuploadExamModalForm } from './reuploadExamModalForm.types';

const ReuploadExamModalForm = ({
  examId,
  onClose,
  onCompleted,
}: IReuploadExamModalForm) => {
  const { data, loading } = useQuery<{ exam: ExamObject }>(
    GET_EXAM_FOR_REUPLOAD,
    {
      variables: { examId },
      skip: !examId,
    },
  );

  const deviceInfo = DEVICES.find(({ id }) => id === data?.exam?.device);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch,
    resetField,
  } = useForm<{ files: FileList }>({
    mode: 'onBlur',
    resolver: useYupValidationResolver(
      getValidationSchema(deviceInfo?.label || '', deviceInfo?.acceptedFiles),
    ),
  });

  const { updateExam } = useUpdateExam();
  const { removeExam } = useRemoveExam();
  const { openFeedbackModal } = useFeedbackModal();

  if (!data?.exam && loading) {
    <ContainerLoading />;
  }

  const onSubmit = async (values: { files: FileList }) => {
    try {
      if (!data?.exam) {
        return;
      }
      await handleUploadSubmit(values, data.exam, updateExam).then(() => {
        onCompleted?.();
        onClose();
      });
    } catch (error) {
      openFeedbackModal({
        type: 'error',
        title: 'Upload Error',
        message: 'Error uploading files, please try again',
      });
      logError({ error });
    }
  };

  const onDelete = () => {
    openFeedbackModal({
      type: 'warning',
      title: 'Delete exam',
      message: 'Are you sure you want to delete this exam?',
      cancelBtn: {
        label: 'No',
      },
      submitBtn: {
        label: 'Delete',
        onClick: async () => {
          await removeExam({ variables: { removeExamId: data?.exam._id } })
            .then(() => {
              onClose();
            })
            .catch((error: unknown) => logError({ error }));
        },
      },
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <ExamDropZone
        register={register}
        exam={data?.exam}
        files={watch('files')}
        onResetField={() => resetField('files')}
        error={errors.files}
      />
      <StyledFooter handleCancel={onClose}>
        <OutlinedButton
          variant="contained"
          onClick={onDelete}
          disabled={isSubmitting}
        >
          DELETE EXAM
        </OutlinedButton>
        <SubmitButton variant="contained" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'UPLOAD'}
        </SubmitButton>
      </StyledFooter>
    </form>
  );
};

export default ReuploadExamModalForm;
