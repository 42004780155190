// get cookie from document, returns undefined if not cookie not found
export const getCookie = (name: string) =>
  document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.at(2);

// delete cookie / force cookie expiration
export const deleteCookie = (name: string) => {
  if (getCookie(name)) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};
