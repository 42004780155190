export const PATIENT_IDENTIFICATION_TYPE = {
  HEALTH: 'health',
  PASSPORT: 'passport',
  TAX: 'tax',
  SSN: 'ssn',
} as const;

export type TPatientIdentificationType =
  (typeof PATIENT_IDENTIFICATION_TYPE)[keyof typeof PATIENT_IDENTIFICATION_TYPE];

export const PatientIdentificationType = Object.values(
  PATIENT_IDENTIFICATION_TYPE,
);

export const PATIENT_IDENTIFICATION_TYPE_LABELS = {
  health: 'Health number',
  passport: 'Passport number',
  tax: 'Tax number',
  ssn: 'Social security',
} as const;
