import * as Sentry from '@sentry/react';
import { onUploadSubmit } from 'components/exams/ExamDropZone/examDropZone.api';
import { ExamStatus } from 'constants/exams';
import { TOperationProps } from 'types/apollo-types';
import { ExamObject, UserObject } from 'types/mongoose-types';

export const handleUploadSubmit = async (
  values: { files: FileList },
  exam: ExamObject,
  updateExam: TOperationProps,
) =>
  await onUploadSubmit({
    files: values.files,
    examId: exam._id.toString(),
    device: exam.device,
    institutionId: (exam.user as UserObject)?.institution?._id.toString(),
  }).then(async () => {
    const msg = `New file uploaded for exam: ${exam?._id.toString() ?? ''}`;
    Sentry.captureMessage(msg);

    // ignore current file extension and transform filename to mp4
    const currentfileName = values.files[0].name.split('.')[0];
    const newFileName = currentfileName + '.mp4';

    await updateExam({
      variables: {
        updateExamId: exam?._id,
        updateExamRecord: {
          media: {
            fullVideo: newFileName,
          },
          status: ExamStatus.UnderAnalysis,
        },
      },
    });
  });
