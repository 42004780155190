import { createContext, useCallback, useContext, useState } from 'react';

import { FeedbackModal, IFeedbackModal } from 'components/layout/FeedbackModal';
import {
  IFeedbackModalContext,
  IFeedbackModalProvider,
} from './feedbackModalProvider.types';

const initialContextState = {
  openFeedbackModal: () => null,
};

const FeedbackModalContext = createContext<IFeedbackModalContext>(
  initialContextState as IFeedbackModalContext,
);

export const useFeedbackModal = (): IFeedbackModalContext =>
  useContext(FeedbackModalContext);

const FeedbackModalProvider = ({
  children,
}: IFeedbackModalProvider): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState<IFeedbackModal | undefined>();

  const openFeedbackModal = useCallback(
    ({
      type,
      title,
      message,
      cancelBtn,
      submitBtn,
      additionalOptions,
    }: IFeedbackModal) => {
      setOpenModal(true);
      setModalProps({
        type,
        title,
        message,
        cancelBtn,
        submitBtn,
        additionalOptions,
      });
    },
    [setOpenModal, setModalProps],
  );

  const closeFeedbackModal = useCallback(() => {
    if (setOpenModal) {
      setOpenModal(false);
    }
  }, [setOpenModal]);

  return (
    <FeedbackModalContext.Provider value={{ openFeedbackModal }}>
      <FeedbackModal
        type={modalProps?.type}
        isOpen={openModal}
        handleClose={closeFeedbackModal}
        title={modalProps?.title}
        message={modalProps?.message}
        cancelBtn={modalProps?.cancelBtn}
        submitBtn={modalProps?.submitBtn}
        additionalOptions={modalProps?.additionalOptions}
      />
      {children}
    </FeedbackModalContext.Provider>
  );
};

export default FeedbackModalProvider;
