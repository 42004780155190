import {
  ApolloError,
  LazyQueryExecFunction,
  MutationFunctionOptions,
  OperationVariables,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { GET_PATIENT_EXAM_DETAILS } from 'components/pages/PatientsView/PatientDetails/PatientExamsDetails/PatientExamDetails.api';
import { displayToast } from 'components/system/Toast';
import { useForm } from 'react-hook-form';
import { TFetchResult, TOperationProps } from 'types/apollo-types';
import useYupValidationResolver from 'utils/useYupValidationResolver';
import { GET_EXAM_STATUS, UPDATE_EXAM } from './examForms.api';
import {
  IExamFormHookProps,
  IExamStatusQueryData,
  IFormFieldValues,
  IUpdateExamMutationData,
  TExamStatusData,
} from './examForms.types';
import { getValidationSchema } from './examForms.yup';
import { usePhysiciansOptions } from './usePhysiciansOptions.hooks';
import { setDefaultValues } from './utils';

export const onUpdateExamError = (responseError: ApolloError) => {
  const errorMessage = responseError?.graphQLErrors?.[0]?.extensions?.message;

  displayToast({
    type: 'error',
    title: 'Error updating exam',
    description:
      errorMessage && typeof errorMessage === 'string'
        ? errorMessage
        : 'Please try again later.',
  });
};

export const useUpdateExam = (options?: MutationFunctionOptions) => {
  const [updateExam, mutationData]: [TOperationProps, IUpdateExamMutationData] =
    useMutation(UPDATE_EXAM, {
      onError: onUpdateExamError,
      refetchQueries: [GET_PATIENT_EXAM_DETAILS],
      ...options,
    });

  const { data, loading, error } = mutationData;
  return { updateExam, data, loading, error };
};

export const useExamStatus = () => {
  const [getExamStatus, queryData]: [
    LazyQueryExecFunction<TExamStatusData, OperationVariables>,
    IExamStatusQueryData,
  ] = useLazyQuery(GET_EXAM_STATUS);

  const { data, loading, error } = queryData;

  return { getExamStatus, data, loading, error };
};

export const useExamForm = ({
  exam,
  onCompleteSubmission,
  onBeforeSubmission,
  nextStatus,
}: IExamFormHookProps) => {
  const formReturn = useForm<IFormFieldValues>({
    mode: 'onBlur',
    resolver: useYupValidationResolver(getValidationSchema()),
  });

  const {
    loading: usersLoading,
    error: usersError,
    physicianOptions,
  } = usePhysiciansOptions({
    onCompleted: (data) => formReturn.reset(setDefaultValues(data, exam)),
  });

  const { updateExam, loading, error: updateExamError } = useUpdateExam();

  const { handleSubmit } = formReturn;

  const onSubmit = async (formData: IFormFieldValues) => {
    onBeforeSubmission?.();
    const informationData = {
      ...formData,
      physician: formData.physician?.value,
      priority: formData.priority?.value || '',
      ...(nextStatus && { status: nextStatus }),
    };

    // update exam in database
    await updateExam({
      variables: {
        updateExamId: exam?._id,
        updateExamRecord: informationData,
      },
      onCompleted: (response: TFetchResult) =>
        void onCompleteSubmission(response),
    });
  };

  return {
    isLoading: loading || usersLoading,
    handleSubmit: handleSubmit(onSubmit),
    formReturn,
    physicianOptions,
    updateExamError,
    usersError,
  };
};
