import { ListItem } from '@mui/material';
import { FC } from 'react';

import { IFeedbackModal } from 'components/layout/FeedbackModal';
import { ActionButton } from 'components/system/buttons';
import { STATIC_ASSETS_URL } from 'constants/api';
import { Types } from 'mongoose';
import { logError } from 'utils/logError';
import NotificationCardContent from './NotificationCardContent';
import { INotificationCard, TOperationProps } from './notifications.types';

const deleteNotification = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  notificationId: Types.ObjectId,
  openFeedbackModal: (props: IFeedbackModal) => void,
  removeNotification: TOperationProps,
) => {
  e.stopPropagation();
  openFeedbackModal({
    type: 'warning',
    title: 'Delete Notification',
    message: 'Are you sure you want to delete this notification?',
    submitBtn: {
      onClick: () => {
        removeNotification({
          variables: {
            notificationId,
          },
        }).catch((error: unknown) => {
          logError({ error });
        });
      },
    },
  });
};

const NotificationCard: FC<INotificationCard> = ({
  data,
  openFeedbackModal,
  updateNotification,
  removeNotification,
}) => (
  <ListItem
    secondaryAction={
      <ActionButton
        onClick={(e) =>
          deleteNotification(e, data._id, openFeedbackModal, removeNotification)
        }
      >
        <img
          src={`${STATIC_ASSETS_URL}/img/icons/trash.svg`}
          alt="delete"
          title="Delete"
        />
      </ActionButton>
    }
  >
    <NotificationCardContent
      data={data}
      handleUpdate={() => {
        void (async () => {
          if (data._id) {
            await updateNotification({
              variables: {
                updateNotificationId: data._id,
              },
            }).catch((error: unknown) => {
              logError({ error });
            });
          }
        })();
      }}
      removeNotification={() =>
        removeNotification({
          variables: { notificationId: data._id },
        })
      }
    />
  </ListItem>
);
export default NotificationCard;
