import { styled } from '@mui/material';

export const StyledIcon = styled('img')({
  width: '32px',
  height: '32px',
  background: '#303746',
  borderRadius: '50%',
  // Keep element behind gradient
  zIndex: '-2',
});
