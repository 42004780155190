import { Avatar, Badge, IconButton, styled } from '@mui/material';
import { FC, MouseEventHandler } from 'react';

import { useSession } from 'providers';
import { getUserInitials } from 'utils/getUserInitials';

interface IStatusButton {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: '20px',
  '& > img': {
    width: '25px',
    height: '25px',
  },
  '& .MuiBadge-badge': {
    transform: 'scale(1) translate(40%, -30%)',
  },
  '& .MuiAvatar-root': {
    width: '30px',
    height: '30px',
    border: `1px solid ${theme.palette.primary.main}`,
    '& > img': {
      height: '50%',
      width: 'auto',
    },
  },
}));

const StatusButton: FC<IStatusButton> = ({ onClick }) => {
  const { user } = useSession();

  return (
    <CustomIconButton onClick={onClick} edge="start">
      <Badge
        color="success"
        variant="dot"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Avatar src="" alt={`Status Icon`} sx={{ fontSize: '14px' }}>
          {user && getUserInitials(user)}
        </Avatar>
      </Badge>
    </CustomIconButton>
  );
};

export default StatusButton;
