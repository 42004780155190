import * as yup from 'yup';
import {
  FORM_ERROR_MESSAGE,
  getEndOfToday,
  MAX_LENGTH,
  MIN_DATE,
} from 'yupHelper';

export const getValidationSchema = () =>
  yup.object({
    examDate: yup
      .date()
      .min(MIN_DATE, FORM_ERROR_MESSAGE.MIN_DATE)
      .max(getEndOfToday(), FORM_ERROR_MESSAGE.DATE_FROM_PAST)
      .typeError(FORM_ERROR_MESSAGE.INVALID_DATE)
      .required(FORM_ERROR_MESSAGE.REQUIRED),
    physician: yup.object().required(FORM_ERROR_MESSAGE.REQUIRED).nullable(),
    priority: yup.object().required(FORM_ERROR_MESSAGE.REQUIRED).nullable(),
    clinicalIndication: yup
      .string()
      .max(MAX_LENGTH.TEXT_AREA, FORM_ERROR_MESSAGE.MAX)
      .required(FORM_ERROR_MESSAGE.REQUIRED),
  });
