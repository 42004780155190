import { Popover } from '@mui/material';
import { FC } from 'react';

import NotificationCard from './NotificationCard';
import { EmptyContainer, NotificationsList } from './notifications.styles';
import { INotificationsMenu } from './notifications.types';

const NotificationsMenu: FC<INotificationsMenu> = ({
  anchorEl,
  isOpen,
  onClose,
  notifications,
  openFeedbackModal,
  updateNotification,
  removeNotification,
}) => {
  const renderEmpty = () => <EmptyContainer>No Notifications</EmptyContainer>;

  const renderContent = () => (
    <NotificationsList
      // close notifications on card click
      onClick={(e) => {
        if (onClose) {
          onClose(e, 'backdropClick');
        }
      }}
    >
      {notifications?.map((notification) => (
        <NotificationCard
          key={notification._id.toString()}
          data={notification}
          openFeedbackModal={openFeedbackModal}
          updateNotification={updateNotification}
          removeNotification={removeNotification}
        />
      ))}
    </NotificationsList>
  );

  return (
    <Popover
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          sx: {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      {notifications?.length ? renderContent() : renderEmpty()}
    </Popover>
  );
};

export default NotificationsMenu;
