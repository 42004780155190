import CloseIcon from '@mui/icons-material/Close';

import { StyledCloseButton } from './components';
import { IModalCloseButton } from './modal.types';

const ModalCloseButton = ({ handleClose }: IModalCloseButton) => {
  return (
    <StyledCloseButton onClick={handleClose}>
      <CloseIcon />
    </StyledCloseButton>
  );
};

export default ModalCloseButton;
