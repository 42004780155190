import { Box, Typography, styled } from '@mui/material';

export const StyledFooter = styled(Box)({
  marginTop: '20px',
});

export const IconWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  '> svg': {
    height: '45px',
    width: '45px',
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  paddingTop: '15px',
  paddingBottom: '15px',
  color: theme.palette.primary.main,
}));

export const StyledMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.texts.main,
  whiteSpace: 'pre-wrap',
}));
