import { MouseEvent, useState } from 'react';

export const useStatus = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isStatusOpen = Boolean(anchorEl);

  const handleStatusButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(isStatusOpen ? null : event.currentTarget);
  };

  return {
    isStatusOpen,
    anchorEl,
    handleStatusButtonClick,
  };
};
