import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import { ObjectSchema, ValidationError } from 'yup';

const useYupValidationResolver = (validationSchema: ObjectSchema<any>) =>
  useCallback(
    async (data: FieldValues) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        if (errors instanceof ValidationError) {
          const allErrors = {};
          errors.inner.forEach((err) => {
            Object.assign(allErrors, {
              [err.path as string]: {
                type: err.type ?? 'validation',
                message: err.message,
              },
            });
          });

          return {
            values: {},
            errors: allErrors,
          };
        } else {
          return {
            values: {},
            errors: {},
          };
        }
      }
    },
    [validationSchema],
  );

export default useYupValidationResolver;
