export const MAX_LENGTH = {
  EMAIL: 50,
  PASSWORD: 40,
  TEXT: 30,
  TEXT_AREA: 1000,
  PHONE: 20,
  FILE_URL: 100,
} as const;

export const MIN_LENGTH = {
  PASSWORD: 6,
};
