import { useQuery } from '@apollo/client';
import { useFeedbackModal } from 'providers';
import { useState } from 'react';
import { UserDocument } from 'types/mongoose-types';
import { getPersonalName } from 'utils/getPersonalName';
import { logError } from 'utils/logError';
import { GET_USERS } from './examForms.api';
import { IPhysicianOptions, IUsersQuery } from './examForms.types';

export const usePhysiciansOptions = ({
  onCompleted,
}: {
  onCompleted: (data: IPhysicianOptions[]) => void;
}) => {
  const [options, setOptions] = useState<IPhysicianOptions[]>([]);
  const { openFeedbackModal } = useFeedbackModal();

  const { loading, error: usersError } = useQuery<IUsersQuery>(GET_USERS, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      logError({ error });
      openFeedbackModal({
        type: 'error',
        title: 'Error',
        message: 'Error fetching users, please try again later.',
      });
    },
    onCompleted: (response) => {
      const physicianOptions = response.users.map((user: UserDocument) => ({
        label: getPersonalName(user),
        value: user._id.toString(),
      }));

      setOptions(physicianOptions);
      onCompleted(physicianOptions);
    },
  });

  return { loading, error: usersError, physicianOptions: options };
};
