import { gql } from '@apollo/client';

export const GET_RECENT_ACTIVITY = gql`
  query RecentActivity($sort: SortFindManyExamInput) {
    recentActivity(sort: $sort) {
      _id
      examDate
      technology
      patient {
        _id
        firstName
        lastName
        avatar
        identificationType
        identificationNumber
      }
      findings {
        _id
        relevant
      }
      status
      createdAt
    }
  }
`;
