import { createContext, useCallback, useContext, useState } from 'react';
import {
  ISessionExpiryModalContext,
  ISessionExpiryModalProvider,
} from './SessionExpiryModalProvider.types';

const initialContextState: ISessionExpiryModalContext = {
  isModalOpen: false,
  openSessionExpiryModal: () => null,
  closeSessionExpiryModal: () => null,
};

const SessionExpiryModalContext =
  createContext<ISessionExpiryModalContext>(initialContextState);

export const useSessionExpiryModal = (): ISessionExpiryModalContext =>
  useContext(SessionExpiryModalContext);

const SessionExpiryModalProvider = ({
  children,
}: ISessionExpiryModalProvider) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openSessionExpiryModal = useCallback(() => setIsModalOpen(true), []);
  const closeSessionExpiryModal = useCallback(() => setIsModalOpen(false), []);

  return (
    <SessionExpiryModalContext.Provider
      value={{ isModalOpen, openSessionExpiryModal, closeSessionExpiryModal }}
    >
      {children}
    </SessionExpiryModalContext.Provider>
  );
};

export default SessionExpiryModalProvider;
