import { gql, useQuery } from '@apollo/client';

import { PatientDocument } from 'types/mongoose-types';

interface IPatientData {
  patients: readonly PatientDocument[];
}

export const GET_PATIENTS = gql`
  query GetPatients($sort: [SortFindManyPatientInput!]) {
    patients(sort: $sort) {
      _id
      firstName
      lastName
      identificationNumber
      avatar
    }
  }
`;

export const useSearchPatients = () => {
  const { loading, data, error } = useQuery<IPatientData>(GET_PATIENTS, {
    variables: {
      sort: ['FIRSTNAME_ASC', 'LASTNAME_ASC'],
    },
  });

  return {
    data: data?.patients,
    loading,
    error,
  };
};
