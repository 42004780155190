import { List, styled } from '@mui/material';

export const EmptyContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: `0px 0px 12px ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.primary.main,
  display: 'flex',
  height: 220,
  justifyContent: 'center',
  margin: 12,
  width: 360,
}));

export const NotificationsList = styled(List)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.secondary,
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: `0px 0px 12px ${theme.palette.primary.main}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  margin: 12,
  maxWidth: 360,
  maxHeight: 400,
  overflow: 'auto',
}));
