import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createRoot } from 'react-dom/client';

import { NAVIGATOR_LOCALE } from 'constants/locales';
import './App.css';
import theme from './style';

import { FeedbackModalProvider } from 'providers';
import ModalProvider from 'providers/ModalProvider/ModalProvider';
import SessionExpiryModalProvider from 'providers/SessionExpiryModalProvider/SessionExpiryModalProvider';
import App from './App';
import ErrorBoundary from './utils/ErrorBoundary';
import axiosDefaults from './utils/axiosDefaults';

axiosDefaults();

const rootElement = document.getElementById('root');
// adapted to use React 18+
const root = createRoot(rootElement ?? document.documentElement);

root.render(
  <ErrorBoundary>
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={NAVIGATOR_LOCALE}
    >
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={theme('dark')}>
          <ModalProvider>
            <FeedbackModalProvider>
              <SessionExpiryModalProvider>
                <App />
              </SessionExpiryModalProvider>
            </FeedbackModalProvider>
          </ModalProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  </ErrorBoundary>,
);
