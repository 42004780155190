export const colors = {
  // usage in both themes
  green: '#4A8900', // cta low
  orange: '#FF5F21', // cta medium
  red: '#F8081A', // cta high
  grey1: '#CECBCB', // texts
  grey2: '#757E8D', // subtitles
  grey3: '#2B2C2E', // type lateral menu
  black1: '#0B0D10', // background lateral (dark), titles (light)
  // usage in dark theme
  white1: '#F9F8FA', // titles
  black2: '#121419', // dashboard category
  black3: '#010104', // background
  mainD: '#73f5f8', // icons text
  // usage in light theme
  white2: '#FCFCFC', // background lateral and dashboard category
  grey4: '#F1F5FB', // background
  mainW: '#08A6AA', // icons text
};

export const gradients = {
  // usage in dark theme
  gradientD: 'linear-gradient(180deg, #00EFD1 0%, #00ACEA 100%)', // icons message
  // usage in light theme
  gradientW: 'linear-gradient(180deg, #010104 0%, #08A6AA 0%, #010104 100%)', // icons message
  tableRow:
    'transparent linear-gradient(270deg, #010104 0%, #010104 14%, #08A6AA5C 47%, #010104 80%, #010104 100%) 0% 0% no-repeat padding-box',
};
