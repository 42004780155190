import { AxiosError } from 'axios';
import { useState } from 'react';

import { axiosRestApi } from 'config/axiosRestApi';
import { FirebaseErrorCodeEnum } from 'constants/firebase';
import { useFeedbackModal } from 'providers';
import { logError } from 'utils/logError';

const DEFAULT = {
  title: 'Authentication Error',
  message: 'Invalid Credentials, please try again.',
};

export const useAuthModalError = () => {
  const { openFeedbackModal } = useFeedbackModal();
  const [loading, setLoading] = useState(false);

  const sendEmailVerification = async (email: string) => {
    try {
      await axiosRestApi.post('/resend-verification', {
        email,
      });
      openFeedbackModal({
        type: 'success',
        title: 'Email Sent',
        message: 'Please check your email to activate your account',
      });
    } catch (error: unknown) {
      logError({ error });
      openFeedbackModal({
        type: 'error',
        title: 'Error sending email verification',
        message: 'Please try again later',
      });
    } finally {
      setLoading(false);
    }
  };

  const displayAuthErrorModal = (
    error: unknown,
    defaultType: 'info' | 'error' | 'warning',
    email?: string,
  ): void => {
    if (error instanceof AxiosError) {
      if (error?.response?.status === 500) {
        openFeedbackModal({
          type: defaultType,
          title: 'Internal Server Error',
          message: 'Please try again.',
        });
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      switch (error.response?.data?.code) {
        case FirebaseErrorCodeEnum.RECENT_SIGN_IN_REQUIRED:
          openFeedbackModal({
            type: defaultType,
            title: 'Recent sign-in required',
            message: 'Please sign in again.',
          });
          return;
        case FirebaseErrorCodeEnum.UNAUTHORIZED:
          openFeedbackModal({
            type: defaultType,
            title: 'Unauthorized',
            message: 'You do not have authorization to perform this action.',
          });
          return;
        case FirebaseErrorCodeEnum.EMAIL_NOT_VERIFIED:
          openFeedbackModal({
            type: defaultType,
            title: 'Email Verification',
            message:
              'You need to verify your email before logging in. Please check your inbox.',
            submitBtn: {
              label: 'Resend Email',
              onClick: () => {
                setLoading(true);
                if (!email) {
                  logError({
                    error: new Error(
                      'Email is required to resend verification email',
                    ),
                  });
                  return;
                }
                void sendEmailVerification(email);
              },
            },
          });
          return;

        default:
          openFeedbackModal({
            type: defaultType,
            ...DEFAULT,
          });
          return;
      }
    }

    return openFeedbackModal({
      type: defaultType,
      ...DEFAULT,
    });
  };

  return { displayAuthErrorModal, loading };
};
