import { gql } from '@apollo/client';

export const GET_EXAM_FOR_REUPLOAD = gql`
  query GetExamForReupload($examId: MongoID!) {
    exam(_id: $examId) {
      _id
      technology
      device
      user {
        firebaseId
        institution {
          _id
        }
      }
    }
  }
`;
