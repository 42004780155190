import { gql } from '@apollo/client';

export const GET_USER_NOTIFICATIONS_QUERY = gql`
  query GetUserNotifications($filter: FilterFindManyNotificationInput!) {
    userNotifications(filter: $filter, sort: CREATED_AT_DESC) {
      _id
      userId
      title
      description
      type
      examId
      read
    }
  }
`;

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription NewNotificationSubscription {
    newNotification {
      _id
      userId
      title
      description
      type
      examId
      read
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($updateNotificationId: MongoID!) {
    updateNotification(_id: $updateNotificationId, record: { read: true }) {
      record {
        _id
      }
    }
  }
`;

export const REMOVE_NOTIFICATION = gql`
  mutation RemoveNotification($notificationId: MongoID!) {
    removeNotification(_id: $notificationId) {
      record {
        _id
      }
    }
  }
`;
