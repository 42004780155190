import { ListItem } from '@mui/material';
import { LinkProps } from 'react-router-dom';
import { StyledItemButton, StyledItemText, StyledLink } from './status.styles';

interface IPopoverItemProps {
  onClick?: LinkProps['onClick'];
  to: string;
  text: string;
  iconSrc: string;
}

const PopoverItem = ({ onClick, to, iconSrc, text }: IPopoverItemProps) => {
  return (
    <ListItem disablePadding>
      <StyledLink to={to} onClick={onClick}>
        <StyledItemButton disableRipple>
          <img src={iconSrc} alt={text} />
          <StyledItemText primary={text} />
        </StyledItemButton>
      </StyledLink>
    </ListItem>
  );
};

export default PopoverItem;
