import { Box, styled } from '@mui/material';

const drawerWidth = 240;

export const AppWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.main,
  height: '100vh',
}));

interface IPageWrapper {
  open: boolean;
}

export const PageWrapper = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<IPageWrapper>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: 150,
  marginLeft: 100,
  backgroundColor: theme.palette.background.main,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));

export const InnerPage = styled(Box)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.xl,
  margin: '0 auto',
}));
