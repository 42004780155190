import { Autocomplete, InputBase, styled } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatPatientName } from 'utils/formatPatientName';
import { useSearchPatients } from './Search.logic';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.subtitle.main,
  '& .MuiInputBase-input': {
    color: theme.palette.subtitle.main,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Search: FC = () => {
  const navigate = useNavigate();

  const { data, loading, error: searchError } = useSearchPatients();

  if (searchError) {
    return null;
  }

  return (
    <Autocomplete
      options={data ?? []}
      getOptionLabel={(option) => formatPatientName(option)}
      renderInput={(params) => {
        const {
          InputLabelProps: _InputLabelProps,
          InputProps,
          inputProps,
          ...rest
        } = params;

        return (
          <StyledInputBase
            placeholder="Search Patient…"
            inputProps={{ 'aria-label': 'search', ...inputProps }}
            {...InputProps}
            {...rest}
          />
        );
      }}
      loading={loading}
      autoComplete
      autoHighlight
      clearOnEscape
      fullWidth
      onChange={(event, value, reason) => {
        event.preventDefault();
        if (reason === 'selectOption' && value) {
          navigate(`/app/patients-view/${value._id.toString()}`);
        }
      }}
    />
  );
};

export default Search;
