import { onFilterSubmit } from 'components/pages/CreateExam/ExamPanel/FilterTab/filterTab.api';
import { IFilterTabValues } from 'components/pages/CreateExam/ExamPanel/examPanel.types';
import { ExamObject, User } from 'types/mongoose-types';
import { logError } from 'utils/logError';

export const handleFilterSubmit = async (
  values: IFilterTabValues,
  exam: ExamObject,
) =>
  await onFilterSubmit({
    values,
    examId: exam?._id.toString(),
    institutionId: (exam.user as User)?.institution?._id.toString(),
  }).catch((error: unknown) => {
    logError({ error });
  });
