import { Box } from '@mui/material';
import { FC } from 'react';

import ContainerLoading from 'components/system/ContainerLoading/ContainerLoading';
import SubmitButton from 'components/system/buttons/SubmitButton';
import { FormTextField } from 'components/system/form';
import { ISignInTabPanel } from '../auth.types';
import { useSignIn } from './signIn.hooks';

const SignInTabPanel: FC<ISignInTabPanel> = ({ handleCloseModal }) => {
  const { isLoading, formHandleSubmit, errors, register } = useSignIn({
    handleCloseModal,
  });

  return isLoading ? (
    <ContainerLoading />
  ) : (
    <form onSubmit={formHandleSubmit} noValidate>
      <Box display="flex" flexDirection="column" paddingX={8} paddingY={3}>
        <>
          <FormTextField
            id="email"
            label="Email"
            variant="standard"
            error={errors.email?.message}
            required
            {...register('email')}
          />
          <FormTextField
            type="password"
            id="password"
            label="Password"
            variant="standard"
            error={errors.password?.message}
            required
            {...register('password')}
          />
        </>
      </Box>
      <Box display="flex" justifyContent="center">
        <SubmitButton type="submit" variant="contained">
          SUBMIT
        </SubmitButton>
      </Box>
    </form>
  );
};

export default SignInTabPanel;
