import { Box, IconButton } from '@mui/material';
import {
  StyledButton,
  StyledDescription,
  StyledIcon,
  StyledLinearProgress,
  StyledTextContainer,
  StyledTitle,
  StyledToastContent,
} from './components';
import {
  ToastTypeEnum,
  closeButtonIconMap,
  toastIconMap,
} from './toast.constants';
import { IToastProps } from './toast.types';

const Toast = ({
  data: {
    title,
    description,
    type,
    action,
    hasCloseButton = true,
    loadingVariant,
    loadingValue,
  },
  closeToast,
}: IToastProps) => {
  return (
    <StyledToastContent toastType={type}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <StyledIcon {...toastIconMap[type]} />
          <StyledTextContainer>
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{description}</StyledDescription>
          </StyledTextContainer>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
            flexShrink: 0,
          }}
        >
          {action && (
            <StyledButton toastType={type} onClick={action.onClick}>
              {action.label}
            </StyledButton>
          )}
          {hasCloseButton && (
            <IconButton
              onClick={closeToast}
              sx={{ width: '23px', height: '23px', padding: '0' }}
            >
              <img src={closeButtonIconMap[type]} alt="close" />
            </IconButton>
          )}
        </Box>
      </Box>
      {type === ToastTypeEnum.LOADING && loadingVariant && (
        <StyledLinearProgress variant={loadingVariant} value={loadingValue} />
      )}
    </StyledToastContent>
  );
};

export default Toast;
