import { Modal, StyledModal } from 'components/system/Modal';
import { useModal } from './ModalProvider';

const ModalRenderer = () => {
  const { content, modalProps, handleCloseModal, openModal } = useModal();

  return (
    <>
      {content && modalProps && (
        <Modal handleClose={handleCloseModal} {...modalProps} open={openModal}>
          {content}
        </Modal>
      )}
      {content && !modalProps && (
        <StyledModal open={openModal} onClose={handleCloseModal}>
          {content}
        </StyledModal>
      )}
    </>
  );
};

export default ModalRenderer;
