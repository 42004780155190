import { Box, CircularProgress } from '@mui/material';
import {
  StyledMessage,
  StyledTitle,
} from 'components/layout/FeedbackModal/feedbackModal.styles';
import { useSessionExpiryModal } from 'providers/SessionExpiryModalProvider/SessionExpiryModalProvider';
import { useCloudflareAuth } from '../hooks/useCloudflareAuth';
import SignInTabPanel from './SignInTabPanel';
import {
  ContentWrapper,
  LogoModalDarkBackdrop,
} from './sessionExpiryModal.styles';

const SessionExpiryModal = () => {
  const { isModalOpen, closeSessionExpiryModal } = useSessionExpiryModal();
  const { loading } = useCloudflareAuth(isModalOpen, closeSessionExpiryModal);

  const onClose = (_event: object, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    } else {
      closeSessionExpiryModal();
    }
  };

  return (
    <LogoModalDarkBackdrop
      hideBackdrop={false}
      openModal={isModalOpen}
      handleCloseModal={closeSessionExpiryModal}
      disableClose
      disableEscapeKeyDown
      onClose={onClose}
    >
      <ContentWrapper>
        <StyledTitle variant="h3" align="center">
          Sign In
        </StyledTitle>
        {loading ? (
          <>
            <StyledTitle variant="h3" align="center">
              Authenticating with cloudflare access...
            </StyledTitle>
            <CircularProgress size={64} />
          </>
        ) : (
          <>
            <StyledMessage variant="body1" align="center">
              Your session has expired. Please sign in again.
            </StyledMessage>
            <Box sx={{ alignSelf: 'stretch' }}>
              <SignInTabPanel handleCloseModal={closeSessionExpiryModal} />
            </Box>
          </>
        )}
      </ContentWrapper>
    </LogoModalDarkBackdrop>
  );
};

export default SessionExpiryModal;
