import { Box } from '@mui/material';
import {
  DropZone,
  DropZoneItem,
  DropZoneList,
  LabelWrapper,
} from 'components/pages/CreateExam/ExamPanel/UploadTab/uploadTab.styles';
import { STATIC_ASSETS_URL } from 'constants/api';
import { DEVICES } from 'constants/products';
import { DropZoneContainer, ErrorHelperText } from './examDropZone.styles';
import { IExamDropZone } from './examDropZone.types';

const ExamDropZone = ({ exam, register, files, error }: IExamDropZone) => {
  const deviceInfo = DEVICES.find(({ id }) => id === exam?.device);
  const filesArray = files ? Object.values(files) : [];

  return (
    <DropZoneContainer>
      <DropZone component="label" hasError={Boolean(error)}>
        <input
          {...register('files')}
          accept={deviceInfo?.acceptedFiles}
          type="file"
          className="inputfile"
          data-multiple-caption="{count} files selected"
          multiple
          autoFocus
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" py="16px">
            <LabelWrapper>
              {!files?.length ? (
                <>
                  <img
                    src={`${STATIC_ASSETS_URL}/img/icons/color-upload.svg`}
                    alt="File Upload"
                  />
                  {'Upload files from computer'}
                </>
              ) : (
                <Box display="flex" flexDirection="column">
                  <span>{'Selected files:'}</span>
                  <DropZoneList>
                    {filesArray.slice(0, 5).map((file: File, index) => (
                      <DropZoneItem key={file.name}>
                        {`${index + 1}: ${file.name}`}
                      </DropZoneItem>
                    ))}
                  </DropZoneList>
                  {filesArray.length > 5 && '...'}
                </Box>
              )}
            </LabelWrapper>
          </Box>
          <ErrorHelperText>{error && error.message}</ErrorHelperText>
        </Box>
      </DropZone>
    </DropZoneContainer>
  );
};

export default ExamDropZone;
