import { StyledTitle } from './components';
import { IModalHeader } from './modal.types';

const ModalHeader = ({ title }: IModalHeader) => {
  return (
    <StyledTitle variant="h3" align="center">
      {title}
    </StyledTitle>
  );
};

export default ModalHeader;
