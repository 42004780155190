import { Box, Typography, styled } from '@mui/material';

export const DropZoneContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const ErrorHelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  height: '16px',
  textTransform: 'none',
}));
