import { gql } from '@apollo/client';

export const GET_PATIENT_DRAFT_EXAMS = gql`
  query Query($examsFilter: FilterFindManyExamInput) {
    exams(filter: $examsFilter) {
      _id
      examDate
      physician
      patient {
        _id
        firstName
        lastName
        avatar
      }
      technology
      device
      priority
      user {
        firstName
        lastName
        firebaseId
        institution {
          _id
        }
      }
      medicalNotes {
        _id
        user {
          _id
          firstName
          lastName
        }
        content
        creationDate
      }
      clinicalIndication
      status
      media {
        fullVideo
      }
    }
  }
`;

export const CREATE_EXAM = gql`
  mutation Mutation($record: CreateOneExamInput!) {
    createExam(record: $record) {
      record {
        _id
        user {
          _id
          institution {
            _id
          }
        }
        patient {
          _id
        }
        technology
        device
        status
        physician
      }
    }
  }
`;

export const REMOVE_EXAM = gql`
  mutation Mutation($removeExamId: MongoID!) {
    removeExam(_id: $removeExamId) {
      record {
        _id
      }
    }
  }
`;
