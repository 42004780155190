import {
  Box,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import { drawerWidth } from '../PageBase/PageBase';

export const CustomDrawer = styled(Drawer)(({ theme, open }) => ({
  backgroundColor: theme.palette.lateralBg.main,
  flexShrink: 0,
  overflow: 'hidden',
  paddingRight: 20,
  whiteSpace: 'nowrap',
  width: 100,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '& .MuiDrawer-paper': {
    width: 100,
    boxSizing: 'border-box',
    overflow: 'hidden',
    backgroundColor: theme.palette.lateralBg.main,
    paddingRight: 20,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  ...(open && {
    width: drawerWidth,
    overflow: 'hidden',
    backgroundColor: theme.palette.lateralBg.main,
    color: '#73f5f8!important',
    paddingRight: 20,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      overflow: 'hidden',
      backgroundColor: theme.palette.lateralBg.main,
      paddingRight: 20,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
}));

export const LogoWrapper = styled('div')({
  display: 'flex',
  paddingTop: 65,
  paddingBottom: 65,
  marginLeft: 35,
});

export const MainLogo = styled('img')({
  width: 40,
  height: 40,
});

export const ListLogo = styled('img')({
  width: 25,
  height: 25,
});

interface ICustomListItemText {
  isActive?: boolean;
}

export const CustomListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<ICustomListItemText>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.primary.main : theme.palette.subtitle.main,
}));

export const CustomList = styled(List)(({ theme }) => ({
  marginLeft: 26,
  color: theme.palette.type.main,
}));

export const CustomListItemIcon = styled(ListItemIcon)({
  minWidth: '57px',
});

export const CustomListLast = styled(List)(({ theme }) => ({
  marginTop: 12,
  marginLeft: 26,
  borderTop: `1px solid ${theme.palette.type.main}`,
  color: theme.palette.type.main,
}));

interface ILastWrapper {
  open: boolean;
}

export const LastWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<ILastWrapper>(({ theme, open }) => ({
  position: 'absolute',
  bottom: '5px',
  width: open ? '215px' : '80px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const AppVersionText = styled(Typography)<{ open: boolean }>(
  ({ theme, open }) => ({
    color: theme.palette.primary.main,
    fontSize: '11px',
    width: '100%',
    marginLeft: '42px',
    visibility: open ? 'visible' : 'hidden',
  }),
);
