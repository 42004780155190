import { ModalCloseButton, StyledModalContent } from 'components/system/Modal';
import { useModal } from 'providers/ModalProvider/ModalProvider';
import ResubmitExamModalContent from './ResubmitExamModalContent';
import { IUseResubmitExamModal } from './resubmitExamModalContent.types';

export const useResubmitExamModal = ({
  onClick,
  onCompleted,
}: IUseResubmitExamModal = {}) => {
  const { handleOpenModal, handleCloseModal } = useModal();

  const openResubmitModal = (examId: string) => {
    onClick?.();
    handleOpenModal(
      <StyledModalContent>
        <ModalCloseButton handleClose={handleCloseModal} />
        <ResubmitExamModalContent
          examId={examId}
          onClose={handleCloseModal}
          onCompleted={onCompleted}
        />
      </StyledModalContent>,
    );
  };

  return { openResubmitModal };
};
