import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { GraphQLError } from 'graphql';

const handleAxiosError = (error: AxiosError) => {
  if (error.response) {
    // The server responded with a status different than 2xx range
    console.log('Axios error data: ', error.response.data);
    console.log('Axios error status: ', error.response.status);
    console.log('Axios error headers ', error.response.headers);
  } else if (error.request) {
    // No response was received
    console.log('Axios error request', error.request);
  } else {
    console.log('No response from server');
  }
};

const handleApolloError = (error: ApolloError) => {
  // log significant apollo error properties
  console.log(`graphQLErrors`, error.graphQLErrors);
  console.log(`message`, error.message);
  console.log(`networkError`, error.networkError);
  console.log(`extraInfo`, error.extraInfo);
};

export const logError = ({
  error,
  ignoreSentry,
}: {
  error: unknown;
  ignoreSentry?: boolean;
}) => {
  if (process.env.REACT_APP_ENV === 'local') {
    console.trace(error);
  }
  if (!ignoreSentry) {
    Sentry.captureException(error);
  }
  if (error instanceof Error) {
    console.log('Error: ', error.message);
  } else if (error instanceof AxiosError) {
    handleAxiosError(error);
  } else if (error instanceof ApolloError) {
    handleApolloError(error);
  } else if (error instanceof GraphQLError) {
    console.log('GraphQL error', error);
  } else {
    // Some other type of error was thrown
    console.log('Unexpected error', error);
  }
};
