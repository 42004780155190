import { TextField, styled } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme, label }) => ({
  width: '100%',
  marginBottom: 30,
  '& .MuiTextField-root': {
    color: theme.palette.texts.main,
  },
  '& .MuiInput-root': {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    '& .Mui-disabled': {
      borderBottom: 'none',
    },
  },
  '&.MuiInputLabel-root': {
    color: theme.palette.primary.main,
  },
  '& .MuiInputBase-input': {
    color: theme.palette.texts.main,
  },
  '& .MuiInputBase-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.6)', // (default alpha is 0.38)
  },
  '& .Mui-disabled': {
    color: theme.palette.primary.main,
    WebkitTextFillColor: `${theme.palette.primary.main}!important`,
    cursor: 'not-allowed',
    border: 'none',
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    top: label ? '50px' : '35px',
  },
  '&label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
  '& .Mui-error:after': {
    borderBottom: 'none',
  },
  '& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
    {
      WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.main} inset !important`,
    },
  '& input:-webkit-autofill': {
    WebkitTextFillColor: `${theme.palette.texts.main} !important`,
  },
}));
