import { NotificationObject } from 'types/mongoose-types';
import NotificationLinkItem from './NotificationLinkItem';
import ResubmitExamContent from './ResubmitExamContent/ResubmitExamContent';
import UploadExamContent from './UploadExamContent/UploadExamContent';

interface INotificationCardContent {
  data: NotificationObject;
  handleUpdate: () => void;
  removeNotification: () => void;
}

const NotificationCardContent = ({
  data,
  handleUpdate,
  removeNotification,
}: INotificationCardContent) => {
  const { type } = data;
  if (type === 'exam_upload_error') {
    return (
      <UploadExamContent
        data={data}
        onOpen={handleUpdate}
        onCompleted={removeNotification}
      />
    );
  } else if (type === 'exam_analysis_error') {
    return (
      <ResubmitExamContent
        data={data}
        onOpen={handleUpdate}
        onCompleted={removeNotification}
      />
    );
  }

  return <NotificationLinkItem data={data} onClick={handleUpdate} />;
};

export default NotificationCardContent;
