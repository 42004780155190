import * as yup from 'yup';
import { FORM_ERROR_MESSAGE, MAX_LENGTH, requiredEmailField } from 'yupHelper';

export const validationSchema = yup.object({
  email: requiredEmailField,
  password: yup
    .string()
    .max(MAX_LENGTH.PASSWORD, FORM_ERROR_MESSAGE.MAX)
    .required(FORM_ERROR_MESSAGE.REQUIRED),
});
