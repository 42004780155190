import { IconButton, styled } from '@mui/material';

const ActionButton = styled(IconButton)({
  '> img': {
    width: '12px',
    height: '12px',
    position: 'absolute',
    ':hover': {
      width: '14px',
      height: '14px',
    },
  },
});

export default ActionButton;
