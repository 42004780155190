import { Box, BoxProps, CircularProgress, styled } from '@mui/material';

const LoadingWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '200px',
}));

const ContainerLoading = (props: BoxProps) => (
  <LoadingWrapper {...props}>
    <CircularProgress size={64} />
  </LoadingWrapper>
);

export default ContainerLoading;
