import { ListItemText } from '@mui/material';
import ListItemLink from 'components/system/ListItemLink/ListItemLink';
import { colors } from 'style/colors';
import { Notification } from 'types/mongoose-types';
import { buildNotificationUrl } from './notifications.helpers';

const primaryTextStyle = {
  color: colors.mainD,
};
const secondaryTextStyle = {
  color: colors.mainW,
};

interface INotificationLinkItem {
  data: Notification;
  onClick: () => void;
}

const NotificationLinkItem = ({ onClick, data }: INotificationLinkItem) => {
  return (
    <ListItemLink
      alignItems="flex-start"
      to={buildNotificationUrl(data)}
      onClick={onClick}
    >
      <ListItemText
        primary={data.title}
        secondary={data.description}
        primaryTypographyProps={{
          variant: 'body1',
          sx: { fontWeight: data.read ? '100' : 'bold', ...primaryTextStyle },
        }}
        secondaryTypographyProps={{
          variant: 'body2',
          sx: { fontWeight: data.read ? '100' : 'bold', ...secondaryTextStyle },
        }}
      />
    </ListItemLink>
  );
};

export default NotificationLinkItem;
