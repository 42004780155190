import { Box, styled } from '@mui/material';

import { gradientColorByType, TToastType } from '../toast.constants';

export const StyledToastContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'toastType',
})(({ toastType }: { toastType: TToastType }) => ({
  // Radial gradient behind text
  '&::before': {
    position: 'absolute',
    width: '212px',
    height: '212px',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 'calc(-1 * (212px / 2) + 32px)',
    background: `radial-gradient(50% 50% at 50% 50%, ${gradientColorByType[toastType]} 0%, rgba(0, 237, 123, 0) 100%)`,
    content: '""',
    zIndex: '-1',
  },
}));
